@import "../../assets/styles/all.scss";

.legal-container {

    background: #f9f9f9;    
    $border-width: 1px;
    $border-color: #CED4DA;

    h3 {
        margin-top: 32px;
    }

    .legal-section {
        margin: 0 auto;
        margin-top: -140px;
        max-width: 836px;
        padding: 80px 0;
        font-size: 14px;
        line-height: calc(22 / 14);
        color: $dark;

        @include media("<desktop-wide") {
            margin-top: -166px;
            max-width: 406px;
        }

        .text-block {

            &:not(:last-child) {
                margin: 30px 0 30px;
            }

            > *:last-child {
                margin-bottom: 0;
            }

            h3 {
                margin-bottom: .6em;
                color: #000;
            }

            p {
                margin-bottom: 1.55em;
            }

            .important-notice {
                margin-top: 30px;
                // color: $ct-text-grey;
                background: white;
                padding: 20px 20px 0;
                border: 1px solid #DEE2E6;

                ol {
                    li {
                        font-weight: bold;
                        margin: 12px 0;
                    }
                }
            }
        } 

        table {

            tbody, td, tfoot, th, thead, tr {
                border: $border-width solid $border-color;
                padding: 16px;
            }

            th {
                color: #000;
                text-align: left;
            }
        }

        .open-close-holder {
            margin: 0 0 45px;
        
            @include media('>=tablet') {
                margin: 0 0 61px;
            }
        
            .open-close {
                border: $border-width solid $border-color;
                border-width: $border-width $border-width 0;
                background: $white;
        
                &:last-child {
                    border-width: $border-width;
                }
        
                &.active {
                    .opener {
                        color: $primary;
        
                        &:before {
                            transform: translateY(-50%) scale(1);
                            opacity: 1;
                        }
        
                        &:after {
                            transform: translateY(-50%) scale(0);
                            opacity: 0;
                        }
                    }
                }
            }
        
            .opener {
                position: relative;
                display: block;
                text-decoration: none;
                margin-bottom: 0;
                padding: 15px 45px 15px 18px;
                overflow: hidden;

        
                // @include media('>=tablet') {
                //     padding: 21px 45px 21px 18px;
                // }
                
                &:hover {
                    color: $ct-blue;
                }
        
                &:focus,
                &:active {
                    outline: none;
                }
        
                &:after,
                &:before {
                    @include animate(opacity transform);
                    content: '';
                    width: 16px;
                    height: 16px;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 10px;
        
                    @include media('>=tablet') {
                        right: 20px;
                    }
                }
        
                &:after {
                    background: no-repeat center/contain url("data:image/svg+xml,%3C!-- Generated by IcoMoon.io --%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='288' height='448' viewBox='0 0 288 448'%3E%3Cpath d='M288.001 177.827c0 2.308-1.155 4.906-2.886 6.637l-134.478 134.478c-1.732 1.732-4.329 2.886-6.637 2.886s-4.906-1.155-6.637-2.886l-134.478-134.478c-1.732-1.732-2.886-4.329-2.886-6.637s1.155-4.906 2.886-6.637l14.429-14.429c1.732-1.732 4.040-2.886 6.637-2.886 2.308 0 4.906 1.155 6.637 2.886l113.411 113.411 113.411-113.411c1.732-1.732 4.329-2.886 6.637-2.886s4.906 1.155 6.637 2.886l14.429 14.429c1.732 1.732 2.886 4.329 2.886 6.637z'%3E%3C/path%3E%3C/svg%3E%0A");
                }
        
                &:before {
                    background: no-repeat center/contain url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='1024' height='1024' viewBox='0 0 1024 1024'%3E%3Cpath d='M810.667 273.493l-60.16-60.16-238.507 238.507-238.507-238.507-60.16 60.16 238.507 238.507-238.507 238.507 60.16 60.16 238.507-238.507 238.507 238.507 60.16-60.16-238.507-238.507 238.507-238.507z'%3E%3C/path%3E%3C/svg%3E%0A");
                    transform: translateY(-50%) scale(0);
                    opacity: 0;
                    right: 12px;
        
                    @include media('>=tablet') {
                        right: 22px;
                    }
                }
            }
        
            .slide {
                padding: 10px 18px 38px 18px;
                display: none;
        
                // @include media('>=tablet') {
                //     padding-right: 45px;
                // }
        
        
                > * {
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }           

            .show {
                display: block;
            }
        }
        
        .number-list {
            @extend %listreset;
            counter-reset: num;
            ul {
                @extend %listreset;
            }
        }
        
        .number-list > li {
            margin: 0 0 40px;
        }
        
        @media (min-width: 768px) {
            .number-list > li {
                margin: 0 0 58px;
            }
        }
        
        .number-list > li > ul {
            counter-reset: num;
        }
        
        .number-list > li > ul .list-title:before {
            margin: 0 7px 0 0;
            content: counters(num, '.');
            counter-increment: num;
        }
        
        
        .number-list > li > ul > li .alpha-list > li {
            padding-bottom: 0;
        }
        
        @media (min-width: 768px) {
            .number-list > li > ul > li .alpha-list > li {
                padding-left: 30px;
            }
        }
        
        .number-list > li > ul > li > ul {
            margin: 0 0 36px;
            counter-reset: alphaList;
        }
        
        .number-list > li > ul > li > ul.alpha-secondary {
            margin-top: -30px;
        }
        
        .number-list > li > ul > li > ul.alpha-secondary li {
            padding-bottom: 0;
        }
        
        .number-list > li > ul > li > ul > li {
            position: relative;
            padding: 0 0 20px 35px;
            counter-increment: alphaList;
        }
        
        @media (min-width: 768px) {
            .number-list > li > ul > li > ul > li {
                padding: 0 0 15px 45px;
            }
        }
        
        .number-list > li > ul > li > ul > li:before {
            position: absolute;
            top: 0;
            left: 0;
            margin: 0 7px 0 0;
            content: '(' counter(alphaList, lower-alpha) ')';
        }
        
        .number-list > li > ul > li > ul > li:last-child {
            padding-bottom: 0;
        }
        
        .number-list > li > ul > li > ul > li > ul {
            padding-top: 12px;
            counter-reset: romanList;
        }
        
        .number-list > li > ul > li > ul > li > ul > li {
            position: relative;
            margin-left: -35px;
            padding: 0 0 20px 35px;
            counter-increment: romanList;
        }
        
        @media (min-width: 768px) {
            .number-list > li > ul > li > ul > li > ul > li {
                margin-left: 0;
                padding: 0 0 10px 45px;
            }
        }
        
        .number-list > li > ul > li > ul > li > ul > li:before {
            position: absolute;
            top: 0;
            left: 0;
            margin: 0 7px 0 0;
            content: '(' counter(romanList, lower-roman) ')';
        }
        
        .number-list > li > ul > li > ul > li > ul > li:last-child {
            padding-bottom: 0;
        }
        
        .number-list h3 {
            color: #000;
        }
        
        .number-list h5,
        .number-list .open-close-holder .opener,
        .open-close-holder .number-list .opener,
        .number-list .h5 {
            margin: 0 0 15px;
        }
        
        .number-list .list-title:before {
            margin: 0 7px 0 0;
            content: counters(num, '.') '.0';
            counter-increment: num;
        }
        
    }
}

.legal-header-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;

    .card-wl {
        margin: 0 6px;
    }
}