.dashboard-header {
  padding: 56px 0 56px 0;

  // @include media('>=desktop-wide') {
  // 	padding: 56px 0 56px 0;
  // }

  .df-row {
    display: block;
    margin: 0;

    @include media(">=desktop-wide") {
      display: flex;
      margin: 0 -41px;
    }

    [class*="col-"] {
      @include media(">=desktop-wide") {
        padding: 0 41px;
      }
    }
  }

  .col-wl {
    @include media(">=desktop-wide") {
      display: flex;
      flex: 1 0 0;
    }
  }

  .col-pg {
    max-width: 100%;
    flex: 0 0 100%;
    margin-top: 50px;
    display: flex;

    @include media(">=desktop-wide") {
      margin-top: 0;
      max-width: 393px;
      flex: 0 0 393px;
    }
  }

  .status-label-list {
    margin-bottom: 20px;

    @include media(">=desktop-wide") {
      margin-bottom: 36px;
    }
  }

  .heading {
    margin-bottom: 30px;
    text-align: center;

    @include media(">=desktop-wide") {
      margin-bottom: 0;
      text-align: left;
    }
  }

  .title {
    display: inline-block;
    vertical-align: middle;
    font-size: 30px;
    line-height: 1.1;
    color: #092645;
    margin-bottom: 1px;

    &:only-child {
      margin-bottom: 0;
    }

    span {
      display: inline-flex;
      align-items: center;
    }

    i {
      margin-left: 16px;
      display: inline-block;
      margin-bottom: 10px;
      transform: matrix(-1, 0, 0, 1, 0, 0);
      font-style: normal;
    }
  }

  .subtitle {
    margin-left: 3px;
    font-size: 20px;
    line-height: 1.2;
    color: rgba(109, 118, 126, 0.7);
    text-transform: capitalize;

    svg {
      margin-right: 11px;
    }
  }

  .text {
    margin-bottom: 20px;
    line-height: 1.41;
    color: #343a40;
    font-size: 20px;
    text-align: center;

    @include media(">=desktop-wide") {
      margin-bottom: 0;
      text-align: left;
    }

    p {
      padding-bottom: 20px;

      @include media(">=desktop-wide") {
        padding-bottom: 0px;
      }
    }
  }

  .back-to-dashboard {
    @include media("<desktop-wide") {
      margin-top: 0;
    }

    a {
      color: $ct-dark-blue;
      text-align: right;

      &:hover {
        color: $ct-blue;
      }
    }
  }

  .block-ms {
    display: flex;
    padding: 20px;
    background: #fff;
    border: 1px solid $gray-300;
    border-radius: 10px;
    font-size: 14px;
    line-height: 1.4;
    color: #857f7f;

    @include media(">=desktop-wide") {
      padding: 20px 27px 20px 20px;
      max-width: 426px;
    }

    p {
      margin-bottom: 0;

      a {
        text-decoration: underline;
        color: #0035f1;

        &:hover {
          text-decoration: none;
        }
      }
    }

    svg {
      max-width: 18px;
      flex: 0 0 18px;
      margin-right: 15px;
      margin-top: 4px;

      @include media(">=850px") {
        margin-right: 24px;
      }
    }
  }

  .footer {
    .btn-holder {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      align-items: center;
      flex-wrap: wrap;

      .btn {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 14px;
        padding: 9px 18px;
        line-height: 1.1;
        border-radius: 6px;
        // margin-bottom: 0;
        // margin-right: 15px;
        margin: 0;
        max-width: 259px;
        flex: 0 0 259px;
        cursor: initial;

        @include media(">=desktop-wide") {
          min-width: 281px;
          flex: 0 0 auto;
          max-width: none;
          width: auto;
        }

        @include media("<desktop-wide") {
          margin: 0 auto;
        }

        svg {
          margin-right: 12px;
        }

        + .status-label-list {
          margin: 20px auto;
        }

        &.orange {
          background: #fc943f;
          color: $white;

          &:hover,
          &:active {
            background: $orange;
            color: $white;
          }
        }

        &.green {
          background: $ct-green;
          color: $white;

          &:hover,
          &:active {
            background: #16782c;
            color: $white;
          }
        }
      }

      .status-label-list {
        margin-bottom: 0;
        font-size: 13px;
        line-height: 18px;

        a {
          cursor: initial;
        }

        @include media(">=phone") {
          max-width: 111px;
          // flex: 0 0 111px;
        }
      }
    }
  }
}

.card-progress {
  background: #f3f8ff;
  border: 1px solid #609eff;
  padding: 30px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  @include media(">=desktop-wide") {
    padding: 30px;
    width: 310px;
    height: 310px;
  }

  .card-text {
    flex: 1 0 0;
    padding-right: 10px;

    .progress-block {
      margin-bottom: 20px;
    }

    strong {
      display: block;
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 1.14;
      color: #797b7e;
      max-width: 105px;

      &.level {
        max-width: none;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .bold {
          margin-right: 10px;
        }

        .status-label-list {
          margin: 10px 0;
        }
      }
    }

    .step {
      margin-bottom: 14px;

      span {
        color: #111;
        // font-weight: 700;
      }
    }

    .not-started {
      margin-bottom: 22px;
    }

    .number {
      font-weight: 700;
      font-size: 38px;
      line-height: 1.2;
      color: #092645;

      &:first-child {
        margin-left: 1px;
      }
    }
  }

  .step-counetr {
    display: flex;
    align-items: flex-end;

    .number {
      display: inline;
      font-size: 30px;
      margin: 0 8px;
      color: #111111;
      line-height: 1;
    }
  }

  .badge {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    z-index: 1;

    span {
      position: absolute;
      left: 50%;
      top: 37%;
      transform: translate(-50%, -50%);
      font-weight: 700;
      font-size: 30px;
      line-height: 36px;
      color: #ffffff;
      text-shadow: 0px 1.97876px 1.97876px rgba(0, 0, 0, 0.25);
    }
  }

  .btn-holder {
    max-width: 100%;
    flex: 0 0 100%;
    margin-top: 12px;

    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 14px;
      line-height: 1.1;
      border-radius: 6px;
      padding: 9px 15px;
      margin-bottom: 0;

      @include media(">=desktop-wide") {
        padding: 9px 20px;
        width: 100%;
        margin: 14px 0 0;
      }

      svg {
        margin-right: 12px;
      }

      &:hover,
      &:active {
        background-color: $ct-blue-links;
        color: $white;
      }
    }

    strong {
      display: block;
      margin-bottom: -4px;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: #797b7e;
    }
  }
}

.status-label-list {
  @extend %listreset;

  display: flex;
  align-items: center;
  width: fit-content;
  padding: 3px 10px;
  background: #c6ffd3;
  border-radius: 24px;
  color: #28a745;
  font-weight: 700;
  font-size: 11px;
  line-height: 12px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;

  @include media(">=desktop-wide") {
    margin-left: 0;
  }

  &.dark {
    background: #fff;
    border: 1px solid #ced4da;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
    border-radius: 20px;
    color: #868e96;
    padding: 10px 15px;
    font-size: 14px;
    margin-bottom: 42px;

    &.no-border-shadow {
      box-shadow: none;
      border: 0;
    }

    @include media(">=desktop-wide") {
      margin-bottom: 12px;
      padding: 8px 13px;
    }
  }

  &.success {
    background: rgba(40, 167, 70, 0.1);
    color: #28a745;
  }

  &.orange {
    background: rgba(242, 152, 74, 0.1);
    color: #f2994a;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
  }

  li {
    + li {
      &:before {
        font-family: "icomoon" !important;
        content: "\e901";
        margin: 0 10px;
        font-size: 10px;
      }
    }
  }

  .active {
    text-transform: capitalize;
    color: #3b4350;
  }

  a {
    color: inherit;
  }
}
