.button-common {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 8px 16px;
	background-color: #d7ea6a !important;
	border-radius: 4.8px;
	border: none;
	color: #08504d !important;
}