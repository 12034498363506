.certification-manager-container {
  background: #f9f9f9;
}

.certification-section {
  padding: 0 0 70px 0;

  @include media(">=desktop-wide") {
    padding: 0 0 58px 0;
  }

  .heading {
    padding: 20px 32px;
    border: 1px solid #ddd5d5;
    background: $ct-grey;
    color: #000;

    @include media(">=desktop-wide") {
      padding: 22px 37px;
    }

    h3 {
      margin-bottom: 0;
      font-size: 18px;
    }
  }

  .content {
    padding: 37px 30px;
    background: #fff;
    border: 1px solid #dee2e6;
    border-width: 0 1px 1px 1px;

    &.content-accordion {
      padding: 0;
      border: 0;
    }
  }

  .content-inner {
    padding: 10px 25px;
    // background: #FFFFFF;
    // border: 1px solid #DEE2E6;
    // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
    // border-radius: 10px;
    text-align: center;

    @include media(">=desktop-wide") {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 0 36px;
      text-align: left;
      gap: 55px;
    }
  }

  .item-title {
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 10px;
    color: #6c757d;
    letter-spacing: 0.6px;

    @include media(">=desktop-wide") {
      margin-bottom: 16px;
      font-size: 11px;
    }
  }

  .wb-title {
    color: $ct-dark-blue;
    font-size: 20px;
    font-weight: bolder;
  }

  .col-img {
    display: flex;
    flex-direction: column;
    max-width: 200px;
    margin: 0 auto;

    @include media(">=desktop-wide") {
      max-width: 373px;
      flex-direction: row;
      width: auto;
      margin-left: -28px;
      margin-right: 0;
    }
  }

  .item-img {
    text-align: center;
    pointer-events: none;

    @include media(">=desktop-wide") {
      max-width: 190px;
      flex: 0 0 190px;
      // padding-right: 60px;
      padding-right: 0;
    }
  }

  .item-descr {
    color: #343a40;
    flex: 1 0 0;
    padding-top: 8px;

    @include media(">=desktop-wide") {
      padding-top: 14px;
    }

    strong {
      display: block;
      margin-bottom: 14px;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
    }

    p {
      margin-bottom: 41px;
      line-height: 1.3;

      @include media(">=desktop-wide") {
        margin-bottom: 30px;
      }
    }

    .btn-warning {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      min-width: 119px;
      font-size: 12px;
      padding: 12px 10px;
      margin: auto;

      @include media(">=desktop-wide") {
        margin: 0;
        padding: 12px 20px;
      }

      svg {
        max-width: 12px;
        flex: 0 0 12px;
        margin-right: 8px;
      }
    }

    .btn {
      margin-right: 5px;

      @include media("<desktop-wide") {
        margin: 5px 0;
      }
    }
  }

  .item-price {
    padding-top: 14px;
    max-width: 100%;
    flex: 0 0 100%;
    margin-bottom: 20px;

    @include media(">=desktop-wide") {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      position: relative;
      left: -50px;
      margin-bottom: 0;
    }

    .item-title {
      margin-bottom: 13px;
    }

    .item-header {
      margin-bottom: 80px;

      @include media("<desktop-wide") {
        margin-bottom: 32px;
      }
    }

    .price {
      color: #343a40;
    }

    .item-content {
      color: #6c757d;
      font-size: 12px;
      padding-bottom: 10px;

      p {
        margin-bottom: 0;
        color: #6c757d;
      }

      a {
        color: blue;
        text-decoration: underline;
      }
    }
  }

  .col-text {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding-top: 32px;

    @include media(">=desktop-wide") {
      flex-direction: row;
      width: auto;
      padding-top: 0;
    }

    @include media("<desktop-wide") {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .workbook {
    width: 70%;

    @include media("<desktop-wide") {
      width: 80%;
      margin: 0 auto;
    }
  }

  .item-order,
  .item-date {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 20px;
    padding-top: 14px;

    .item-title {
      margin-bottom: 14px;
    }

    .item-header {
      &:first-child {
        margin-bottom: 52px;

        @include media("<desktop-wide") {
          margin-bottom: 14px;
        }
      }
    }

    @include media("<desktop-wide") {
      padding-bottom: 20px;
    }
  }

  .item-order {
    padding-right: 5px;

    .order-code {
      text-decoration: underline;
      color: #343a40;
    }
  }

  time,
  .order-price {
    color: #343a40;
  }

  .item-date {
    padding-top: 0;

    @include media(">=desktop-wide") {
      width: 154px;
      padding-top: 14px;
    }

    .item-header {
      @include media(">=desktop-wide") {
        max-width: 103px;
        margin-left: auto;
      }
    }
  }

  p {
    font-size: 12px;
    line-height: 1.3;
    color: #6c757d;

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .tabs-steps {
    margin: 0;
    padding: 40px 0;
    border-radius: 0;
    margin-bottom: -1px;
    border: 1px solid #ced4da;

    @include media(">=desktop-wide") {
      padding: 30px 30px 42px 30px;
    }

    .col {
      &:first-child {
        margin: 0;
      }

      &:last-child {
        width: 100%;

        @include media(">=desktop-wide") {
          transform: translate(30px, 1px);
        }
      }

      strong {
        margin-bottom: 17px;
        display: block;
        text-align: center;

        @include media(">=desktop-wide") {
          text-align: left;
        }
      }
    }

    h3 {
      font-size: 24px;
      line-height: 1.2;
      margin: 13px 0 13px;
    }

    .buttons-holder {
      > * {
        font-size: 12px;
        line-height: 12px;
        padding: 5px 9px;
        margin: 0 8px 12px;
        cursor: initial;

        .number {
          width: 20px;
          height: 20px;
        }
      }
    }

    .slide-columns {
      opacity: 1;

      p {
        color: $black;
        font-size: 14px;
        margin-bottom: 2px;
      }
    }

    .text {
      padding-right: 45px;

      p {
        font-size: 16px;
        line-height: 1.4;
      }
    }

    .col-content-holder {
      align-items: flex-start;
    }

    .badge {
      width: 90px;
      height: auto;
      margin: 0 33px 0 -5px;

      img {
        max-width: 90px;
        pointer-events: none;
      }

      span {
        font-size: 42px;
      }
    }

    .tab-list-info {
      margin: 17px 0 0;
      right: 15px;

      .difficulty {
        strong {
          font-size: 15px;
          margin-right: 4px;
          margin-bottom: 0;
        }

        img {
          width: 35px;
          height: auto;
          transform: translate(0, -6px);
        }
      }

      p {
        strong {
          text-align: right;

          @include media(">=desktop-wide") {
            text-align: center;
          }
        }
      }

      li {
        margin: 0 0 18px;
      }
    }
  }

  .text-audit {
    max-width: 234px;
    margin: 0 auto 35px;

    strong {
      display: block;
      color: #6c757d;
    }

    p {
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      color: #6c757d;
    }
  }
}
