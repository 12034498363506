@import "../../assets/styles/all.scss";

.auth-super-container {
  width: 100%;
  min-height: 100vh;
  background-color: $ct-blue-links;
  margin: 0 auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  line-height: calc(20 / 12);

  .small-footer {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include media("<=widescreen") {
      text-align: center;
      width: 60%;
      margin-bottom: 40px;
    }

    @include media(">=widescreen") {
      text-align: center;
      width: 60%;
      margin-bottom: 40px;
    }

    div {
      font-size: 12px;
      color: $white;
      padding: 0;
      margin: 0;

      p {
        margin: 0;

        @include media("<desktop") {
          line-height: 18px;
        }
      }

      a {
        color: $ct-dark-blue;

        &:hover {
          color: $ct-light-blue;
        }
      }
    }

    .sf-links {
      display: flex;
      justify-content: space-around;
      flex-direction: row;
      width: 180px;
      color: $ct-dark-blue;
    }
  }

  .auth-container {
    margin: 100px auto;
    padding: 49px;
    border: 1px solid #dee2e6;
    border-radius: 15px;
    background-color: $white;
    width: 460px;

    @include media("<phone") {
      align-items: center;
      justify-content: center;
      max-width: 320px;
      padding: 45px;
      margin: 18% auto;
    }
  }

  .logo {
    flex-shrink: 0;
    width: 140px;
    height: fit-content;
    padding-bottom: 30px;

    @include media("<desktop") {
      max-width: 152px;
      margin: 0;
    }

    img {
      width: 100%;
    }
  }

  .auth-title {
    font-size: 30px;
    font-weight: 700;
    color: $ct-dark-blue;
    line-height: 36px;
  }

  .subtitle {
    padding: 18px 0 22px;

    a {
      color: $ct-blue-links;
      text-decoration: underline;

      &:hover {
        color: $ct-blue;
      }
    }
  }

  .mt-30 {
    margin-top: 30px;

    button {
      margin: 0 2px 0;

      @include media("<=phone") {
        margin: 0 0 10px 0;
      }
    }
  }
}
