.fake-input {
  display: flex;
  gap: 10px;
  align-items: center;

  .input[type="checkbox"] {
    align-self: center;
  }

  .input[type="checkbox"]:focus {
    outline: 1px solid $ct-blue-links;
  }
}
