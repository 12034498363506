// forms
form,
fieldset {
  margin: 0;
  padding: 0;
  border-style: none;
}

input[type='text'],
input[type='tel'],
input[type='email'],
input[type='search'],
input[type='password'],
input[type='url'],
	// input[type='date'],
textarea {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  background: #f3f3f3;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  width: 100%;
  height: 25px;
  padding: 0 6px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #6c757d;

  @include media(">=widescreen") {
    font-size: 14px;
  }

  &:not(textarea) {
    height: 33px;

    @include media(">=widescreen") {
      height: 42px;
    }
  }

  &:focus {
    outline: none;
  }

  @include placeholder {
    color: $placeholder-color;
  }
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

// input[type="search"] {
//   -webkit-appearance: textfield;
// }

textarea {
  resize: vertical;
  vertical-align: top;
  overflow: auto;
}

button,
input[type="button"],
input[type="reset"],
input[type="file"],
input[type="submit"] {
  @include animate(background);
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  font-family: $base-font-sans-serif;
  padding: $submit-padding;
  font-size: $submit-font-size;
  line-height: $submit-line-height;
  color: $submit-color;
  outline: none;
  cursor: pointer;
  border: $submit-border;
  border-radius: $input-border-radius;
  background: $submit-background;

  &:hover {
    background: $submit-hover-background;
    color: $submit-hover-color;
  }
}

.form-validation {
  .input-error {
    border-bottom-color: red;

    & + .error-text {
      display: block;
    }
  }

  .error-text {
    display: none;
    font-size: 0.7em;
    color: red;
    margin: 0.3em 0 0;
  }
}

.custom-checkbox,
.form-elements .custom-checkbox {
  display: flex;
  align-items: flex-start;
  cursor: pointer;

  input[type="checkbox"] {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    cursor: pointer;
    flex-shrink: 0;
    width: 15.4px;
    height: 16px;
    background-color: rgba(40, 167, 69, 0.1);
    background-image: none;
    border: 1.22135px solid #ced4da;
    border-radius: 4.88539px;
    margin: 2px 18px 0 0;

    &:checked {
      background-color: rgba(40, 167, 69, 0.1);
      background-image: url("../../../images/check.png");
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 9px;
    }
  }
}

.sect-multiple {
  .jcf-list-content {
    .jcf-option {
      padding-right: 44px;
    }
  }
}

.jcf-select-opener {
  position: absolute;
  right: 8px;
  top: 50%;
  width: 20px;
  height: 20px;
  background: url("../../../images/select-arrow.svg") no-repeat center;
  background-size: 50%;
  transform: translateY(-50%);
}

.jcf-select {
  position: relative;
  display: flex;
  align-items: center;
  text-align: left;
  line-height: 1;
  color: #6c757d;
  cursor: pointer;

  .jcf-select-drop {
    position: absolute;
    width: calc(100% + 2px) !important;
    left: -1px;
    top: calc(100% - 1px);
    border: 1px solid #ced4da;
    border-radius: 0px 0px 10px 10px;
    background-color: $white;
    z-index: 1;
  }

  .jcf-list-content {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;

    ul {
      @extend %listreset;

      li:first-child {
        .jcf-selected {
          display: none;
        }
      }
    }

    .jcf-option {
      display: flex;
      align-items: center;
      padding: 4px 8px;
      min-height: 34px;
      transition: 0.3s background;

      &:hover {
        background: #f5f5f5;
      }

      &.jcf-selected {
        background: #ecfff0 url("../../../images/check-icon.svg") no-repeat
          center;
        background-position: 96% center;
      }
    }
  }
}
