@import "../../assets/styles/all.scss";

#footer {
	text-align: center;
	color: $gray-500;
	font-size: 11px;
	line-height: 16px;
	background: $white;
	padding: 70px 0;
	border: 1px solid #d0d0d0;
	border-width: 1px 0 0 0;

	@include media("≥desktop-wide") {
		text-align: left;
	}

	.container {
		@include media(">widescreen-sm") {
			max-width: 1440px;
		}
	}
}

.footer-content {
	max-width: 796px;
	display: flex;
	justify-content: space-between;
	margin: 0 auto;

	@include media(">widescreen-sm") {
		max-width: 100%;
	}
}

.footer-logo {
	flex-shrink: 0;
	width: 152px;
	margin: 0 auto;

	@include media(">=desktop-wide") {
		width: 152px;
		margin: 0;
	}

	@include media(">=widescreen") {
		width: 152px;
		margin: -3px 0 0 0;
	}

	img {
		width: 100%;
	}
}

.footer-info {
	color: #b4b4b4;
	margin-bottom: 30px;

	@include media("<desktop-wide") {
		margin-bottom: 30px;
	}
}

.footer-contacts {
	display: flex;
	flex-direction: column;
	gap: 35px;

	@include media("≥desktop-wide") {
		flex-direction: row;
		justify-content: flex-end;
		max-width: 62.3%;
		flex: 0 0 50%;
		gap: 30px;
		padding: 4px 0 0;
	}

	@include media("≥desktop-wide") {
		padding: 1px 0 0;
	}

	@include media(">=widescreen") {
		max-width: 64%;
		flex: 0 0 50%;
		padding: 0;
	}
}

.footer-contact-item {
	h5 {
		font-size: 14px;
		line-height: 20px;
		color: #333;
		margin: 0 0 11px;

		// @include media('>=desktop-wide') {
		// 	font-size: 20px;
		// 	line-height: 24px;
		// 	margin: 0 0 18px;
		// }

		@include media("<=phone") {
			font-size: 16px;
			line-height: 22px;
		}

		@include media(">=widescreen") {
			margin-bottom: 25px;
		}
	}

	ul {
		@extend %listreset;

		li {
			&.active {
				a {
					color: $white;

					&:hover {
						color: #437df5;
					}

					&:active {
						color: $white;
					}
				}
			}
		}
	}

	a {
		font-size: 14px;
		line-height: 20px;
		color: $blue;

		&:hover {
			color: $ct-blue;
		}

		&:active {
			color: $white;
		}
	}
}

.copyright-block {
	max-width: 237px;
	font-size: 12px;
	line-height: calc(20 / 12);
	// text-align: center;
	color: #979797;
	margin: 25px auto 0;

	@include media("<desktop-wide") {
		width: 494px;
		margin: 25px -34px 0;
	}

	@include media(">=desktop-wide") {
		width: 494px;
		margin: 20px auto 0;
	}

	@include media(">=widescreen") {
		width: 556px;
		padding: 0;
		margin: 25px auto 0;
	}

	p {
		margin: 0;
	}

	span {
		br {
			@include media(">=tablet") {
				display: none;
			}
		}
		@include media("<=tablet") {
			display: inline-block;
			margin: 0 auto;
		}
	}
}
