@import "../../assets/styles/all.scss";

.full-input {
  background-color: $white;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  width: 100%;

  &:last-of-type {
    margin-bottom: 20px;
  }

  // &:hover, &:focus, &:active {
  //   border: 2px solid $ct-blue-links;
  // }
}

.form-input {
  &.code-input {
    margin-top: -20px;
    background: #f9f9f9 !important;
    padding: 16px 10px !important;
    border: 1px solid #dee2e6 !important;
    border-radius: 4px;
    font: 700 20px/24px Arial, Helvetica, sans-serif;
    color: #092645;
    letter-spacing: 5px;
    height: auto;
    text-align: center;
  }
}

.form-select {
  border: 1px solid #dee2e6;
  border-radius: 4px;
  padding: 2px 0px 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
