.main-dashboard-container {
  background: #f9f9f9;
}

.level-certification-block {
  padding-bottom: 56px;
  color: $ct-dark-blue;

  .content {
    padding: 42px 30px 36px 30px;
    background: $ct-grey;
    border: 1px solid #ddd5d5;

    @include media(">=desktop-wide") {
      padding: 39px 31px 42px 27px;
    }
  }

  .completed {
    background: #e7f9eb;
    border: 1px solid $ct-green;
  }

  .block-title {
    display: flex;
    align-items: center;
    font-size: 24px;
    margin-bottom: 34px;

    @include media(">=desktop-wide") {
      margin-bottom: 30px;
    }

    svg {
      margin-right: 15px;
      min-width: 25px;
    }
  }

  .completed-title {
    color: $ct-green;

    svg {
      path {
        fill: $ct-green;
      }
    }
  }

  .step-item {
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      border: 1px solid $gray-300;
      padding: 25px 15px 15px;
      background: $white;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
      border-radius: 10px;
      color: $gray-800;

      @include media(">=desktop-wide") {
        padding: 15px 22px 15px 24px;
        flex-direction: row;
        height: 76px;
      }
    }

    img {
      width: 25px;

      @include media(">=phone") {
        width: 34px;
      }

      + strong {
        margin-left: 10px;

        @include media(">=phone") {
          margin-left: 17px;
        }
      }
    }

    .step-name {
      display: flex;
      align-items: center;

      @include media("<desktop-wide") {
        margin-bottom: 20px;
      }
    }

    strong {
      display: block;
      font-size: 18px;
    }

    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 115px;
      padding: 10px 10px;
      font-size: 14px;
      border-radius: 25px;

      @include media(">=desktop-wide") {
        padding: 13px 31px;
      }

      svg {
        min-width: 18px;
        flex: 0 0 18px;
        margin-right: 6px;
      }

      &:hover {
        color: $white;
      }
    }
  }
}
