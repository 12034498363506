.step-section {
  background: $primary;
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding: 109px 0 83px;

  @include media(">=desktop-wide") {
    padding: 133px 0 130px;
  }

  @include media(">=widescreen") {
    padding: 133px 0 130px;
  }

  .container {
    &.head-mod {
      @include media("<desktop-wide") {
        max-width: 450px;
      }
    }
  }

  .d-desktop {
    @include media("<desktop-wide") {
      display: none;
    }
    strong {
      @include media(">=desktop-wide") {
        display: block;
        font-size: 15.2717px;
        margin: 0 0 12px;
      }
      @include media(">=widescreen") {
        display: block;
        font-size: 18px;
        line-height: 1;
        margin: 0 0 25px;
      }
    }
  }

  .d-mobile {
    text-align: center;

    @include media(">=desktop-wide") {
      display: none;
    }

    strong {
      display: block;
      font-size: 18px;
      line-height: calc(21 / 18);
      margin: 0 0 32px;
    }
  }

  .buttons-holder {
    display: flex;
    flex-wrap: wrap;
    line-height: 1;
    margin: 0 -5px;

    @include media("<desktop-wide") {
      flex-direction: column;
      align-items: center;
    }

    @include media(">=desktop-wide") {
      margin: 0 -6px;
    }

    @include media(">=desktop-wide") {
      margin: 0 -6px;
    }

    @include media(">=widescreen") {
      margin: 0 -7px;
    }

    &:last-child {
      margin-bottom: -10px;

      @include media(">=desktop-wide") {
        margin-bottom: -14px;
      }

      @include media(">=widescreen") {
        margin-bottom: -16px;
      }
    }

    > * {
      display: inline-flex;
      vertical-align: top;
      align-items: center;
      padding: 8px 15px;
      margin: 0 5px 14px;
      font-size: 15px;

      @include media(">=desktop-wide") {
        font-size: 13px;
        padding: 8px 15px;
        margin: 0 5px 10px;
      }

      @include media(">=desktop-wide") {
        font-size: 11px;
        padding: 5px 7px;
        margin: 0 7px 12px;
      }

      @include media(">=desktop-wide") {
        padding: 5px 10px;
        margin: 0 6px 14px;
      }

      @include media(">=widescreen") {
        margin: 0 7px 16px;
        font-size: 13px;
        padding: 6px 12px;
      }

      .number {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: #e1ffe8;
        color: #28a745;
        width: 20px;
        height: 20px;
        font-weight: 400;
        margin: 0 0 0 5px;

        @include media(">=phone") {
          margin: 0 0 0 8px;
          width: 27px;
          height: 27px;
        }

        @include media(">=desktop-wide") {
          width: 24px;
          height: 24px;
        }

        @include media(">=desktop-wide") {
          margin: 0 0 0 5px;
          width: 19px;
          height: 19px;
        }

        @include media(">=desktop-wide") {
          margin: 0 0 0 6px;
          width: 21px;
          height: 21px;
        }

        @include media(">=widescreen") {
          width: 24px;
          height: 24px;
        }
      }
    }

    .btn {
      background: #e9ecf4;
      color: $black;

      &:hover {
        background: darken(#e9ecf4, 5%);
      }

      &.disable {
        opacity: 0.7;
        pointer-events: none;
        background: #e9ecef;
        font-size: 11px;

        > * {
          opacity: 0.7;
        }
      }
    }
  }

  .entry-decor {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: -90%;
    right: 0;
    margin: 0;
    z-index: -1;

    @include media("<phone") {
      display: none;
    }

    @include media(">=desktop-wide") {
      left: -26%;
    }

    @include media(">=desktop-wide") {
      left: 50%;
      transform: translate(-50%);
      width: 100%;
      max-width: 1440px;
      right: auto;
    }

    img {
      width: 100%;
    }
  }

  h2 {
    font-size: 42px;
    line-height: calc(48 / 42);
    color: $white;
    margin: 0 0 42px;

    @include media(">=desktop-wide") {
      font-size: 40px;
      line-height: calc(46 / 40);
      margin: 0 0 31px;
    }

    @include media(">=widescreen") {
      font-size: 50px;
      line-height: 57px;
      margin: 0 0 24px;
    }

    span {
      @include media("<desktop-wide") {
        display: block;
        font-size: 32px;
        line-height: calc(36.8 / 32);
        color: $white;
        margin: -1px 0 0;
      }
    }

    .deliver {
      @include media("<desktop-wide") {
        display: none;
      }
    }
  }

  .bullet-list {
    @extend %listreset;
    color: #a2a3a4;
    font-size: 16px;
    line-height: calc(22 / 16);
    margin: 0;
    padding: 0 19px 0 7px;

    @include media(">=desktop-wide") {
      max-width: 906px;
      margin: 0 auto;
      font-size: 14px;
      line-height: calc(20 / 14);
      padding: 0;
    }

    li {
      display: flex;

      &:before {
        content: "•";
        margin: 0 8px 0 0;
      }
    }
  }
}

.step-heading {
  font-size: 18px;
  line-height: calc(24 / 18);
  text-align: center;
  color: $warning;
  margin: 0 auto 101px;
  max-width: 855px;

  @include media(">=desktop-wide") {
    max-width: 764px;
    margin: 0 auto 70px;
  }

  > * {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.tabs-steps {
  position: relative;
  z-index: 1;
  margin: 0 auto 89px;
  background: $white;

  @include media(">=desktop-wide") {
    max-width: 860px;
    border-radius: 7px;
    overflow: hidden;
    border: 1px solid #ced4da;
    margin-bottom: 48px;
  }

  @include media(">=widescreen") {
    max-width: 1014px;
    margin-bottom: 44px;
  }

  p {
    font-size: 18px;
    line-height: calc(24 / 18);
    margin: 0 0 21px;

    @include media(">=desktop-wide") {
      font-size: 15px;
      line-height: calc(19 / 15);
      margin: 0 0 18px;
    }

    @include media(">=widescreen") {
      font-size: 18px;
      line-height: calc(23 / 18);
      margin: 0 0 21px;
    }

    strong {
      @include media("<desktop-wide") {
        font-size: 18px;
        line-height: calc(22 / 18);
      }
    }
  }

  h3 {
    font-size: 38px;
    line-height: calc(43 / 38);
    margin: 0 0 15px;

    @include media(">=desktop-wide") {
      font-size: 25px;
      line-height: calc(30 / 25);
      margin: 13px 0 25px;
    }

    @include media(">=widescreen") {
      font-size: 30px;
      line-height: calc(35 / 30);
      margin: 17px 0 32px;
    }
  }

  .tabset {
    @extend %listreset;
    display: none;

    @include media(">=desktop-wide") {
      display: flex;
    }

    li {
      flex: 1 0;

      &:last-child {
        a {
          border: none;
        }
      }

      a {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: #e9ecef;
        font-weight: 700;
        color: #6c757d;
        font-size: 19px;
        line-height: 1.2;
        border-right: 1px solid #ced4da;
        padding: 10px 20px;
        min-height: 81px;

        @include media(">=desktop-wide") {
          padding: 16px 20px 7px;
        }

        @include media(">=desktop-wide") {
          font-size: 20px;
          min-height: 87px;
          padding: 15px 20px 10px;
        }

        @include media(">=widescreen") {
          font-size: 24px;
          min-height: 102px;
          padding: 17px 25px 13px;
        }

        &.active {
          color: #343a40;
          background: $white;

          .info-steps-holder {
            opacity: 1;
            visibility: visible;
          }
        }

        &:hover {
          color: #343a40;
        }

        span:not(.info-steps-holder) {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        svg {
          width: 12px;
          max-width: 100%;
          height: auto;

          @include media(">=desktop-wide") {
          }

          @include media(">=desktop-wide") {
          }

          @include media(">=widescreen") {
            width: 14px;
          }

          path {
            fill: currentColor;
          }
        }
      }
    }
  }

  .info-steps-holder {
    @include animate(opacity visibility, 0.25s);
    display: flex;
    align-items: center;
    color: #868e96;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    line-height: 1;
    margin: 0 0 43px;

    @include media(">=desktop-wide") {
      opacity: 0;
      visibility: hidden;
      font-size: 9.5px;
      margin: 0;
    }

    @include media(">=desktop-wide") {
      font-size: 10.2px;
    }

    @include media(">=widescreen") {
      font-size: 12px;
    }

    svg {
      max-width: 100%;
      height: auto;
      width: 8px;
      margin: 0 7px 2px 0;

      @include media(">=phone") {
        width: 12px;
        margin: 0 11px 0 0;
      }

      @include media(">=desktop-wide") {
        width: 11px;
        margin: 0 9px 0 0;
      }

      @include media(">=widescreen") {
        width: 13px;
        margin: 0 10px 0 0;
      }

      path {
        fill: currentColor;
      }
    }
  }

  .opener {
    display: block;
    font-size: 24px;
    font-weight: 700;
    color: $black;
    border: 1px solid #ced4da;
    border-width: 1px 0 0;

    @include media(">=desktop-wide") {
      font-size: 30px;
      border-width: 2px 0 0;
    }

    @include media(">=desktop-wide") {
      display: none;
    }

    span {
      position: relative;
      display: block;
      padding: 16px 55px 16px 38px;

      @include media(">=phone") {
        padding: 16px 75px 16px 58px;
      }

      @include media("<desktop-wide") {
        margin: 0 auto;
        max-width: 480px;
      }

      @include media(">=desktop-wide") {
        padding: 34px 103px 34px 73px;
      }

      &:before {
        position: absolute;
        content: "";
        top: 50%;
        transform: translateY(-50%);
        width: 16px;
        height: 10px;
        background-image: url(../../../images/arrow-down.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        right: 38px;

        @include media(">=phone") {
          right: 59px;
        }
      }
    }
  }

  .slide {
    padding: 78px 25px 61px;

    @include media(">=phone") {
      padding: 78px 57px 61px;
    }

    @include media("<desktop-wide") {
      position: relative;
      border: 1px solid #ced4da;
      border-width: 2px 0 0;
    }

    @include media(">=desktop-wide") {
      padding: 35px 22px 30px 40px;
    }

    @include media(">=widescreen") {
      padding: 41px 31px 40px 47px;
    }

    &.active {
      .slide-columns {
        @include media(">=desktop-wide") {
          opacity: 1;
        }
      }
    }

    .info-steps-holder {
      @include media(">=desktop-wide") {
        display: none;
      }
    }
  }

  .slide-columns {
    @include animate(opacity, 0.25s);
    padding-left: 15px;
    padding-right: 15px;

    @include media(">=desktop-wide") {
      display: flex;
      opacity: 0;
    }

    @include media("<desktop-wide") {
      position: relative;
      max-width: 366px;
      margin: 0 auto;
    }

    .col {
      &:first-child {
        @include media(">=desktop-wide") {
          margin: 0 108px 0 0;
          flex: 3 0;
        }

        @include media(">=widescreen") {
          margin: 0 130px 0 0;
        }
      }

      &:last-child {
        @include media(">=desktop-wide") {
          flex-shrink: 0;
          display: flex;
          flex-direction: column;
          width: 163px;
        }
        @include media(">=widescreen") {
          width: 188px;
        }
      }
    }
  }

  .col-content-holder {
    margin: 0 0 53px;

    @include media(">=desktop-wide") {
      display: flex;
    }

    @include media(">=desktop-wide") {
      margin: 0 0 40px;
    }

    @include media(">=desktop-wide") {
      margin: 0 0 36px;
    }

    @include media(">=widescreen") {
      margin: 0 0 44px;
    }
  }

  .badge {
    position: relative;
    width: 130px;
    margin: 0 0 42px -4px;

    @include media(">=phone") {
      width: 154px;
    }

    @include media(">=desktop-wide") {
      width: 85px;
      margin: 0 36px 0 -2px;
    }

    @include media(">=desktop-wide") {
      width: 96px;
      margin: 0 32px 0 -4px;
    }

    @include media(">=widescreen") {
      width: 112px;
      margin: 0 40px 0 -2px;
    }

    img {
      width: 100%;
    }

    span {
      position: absolute;
      top: 42%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      font-weight: 700;
      font-size: 60px;
      line-height: 1;
      text-transform: uppercase;
      color: $white;
      text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);

      @include media(">=desktop-wide") {
        font-size: 43px;
        top: 43%;
      }

      @include media(">=desktop-wide") {
        font-size: 45px;
      }

      @include media(">=widescreen") {
        font-size: 54px;
      }
    }
  }

  .text {
    @include media("<desktop-wide") {
      max-width: 350px;
    }

    @include media(">=desktop-wide") {
      flex: 1 0;
    }

    > * {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .content-item {
    &:last-child {
      .opener {
        border-width: 1px 0;
      }
    }

    &.active {
      .opener {
        &:after {
          border-width: 1px 0;
        }
      }
    }
  }

  .buttons-holder {
    display: flex;
    flex-wrap: wrap;
    line-height: 1;
    margin: 0 -5px;

    @include media("<desktop-wide") {
      flex-direction: column;
      align-items: center;
    }

    @include media(">=desktop-wide") {
      margin: 0 -6px;
    }

    @include media(">=desktop-wide") {
      margin: 0 -6px;
    }

    @include media(">=widescreen") {
      margin: 0 -7px;
    }

    &:last-child {
      margin-bottom: -10px;

      @include media(">=desktop-wide") {
        margin-bottom: -14px;
      }

      @include media(">=widescreen") {
        margin-bottom: -16px;
      }
    }

    > * {
      display: inline-flex;
      vertical-align: top;
      align-items: center;
      padding: 8px 15px;
      margin: 0 5px 14px;
      font-size: 15px;

      @include media(">=desktop-wide") {
        font-size: 13px;
        padding: 8px 15px;
        margin: 0 5px 10px;
      }

      @include media(">=desktop-wide") {
        font-size: 11px;
        padding: 5px 7px;
        margin: 0 7px 12px;
      }

      @include media(">=desktop-wide") {
        padding: 5px 10px;
        margin: 0 6px 14px;
      }

      @include media(">=widescreen") {
        margin: 0 7px 16px;
        font-size: 13px;
        padding: 6px 12px;
      }

      .number {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: #e1ffe8;
        color: #28a745;
        width: 20px;
        height: 20px;
        font-weight: 400;
        margin: 0 0 0 5px;

        @include media(">=phone") {
          margin: 0 0 0 8px;
          width: 27px;
          height: 27px;
        }

        @include media(">=desktop-wide") {
          width: 24px;
          height: 24px;
        }

        @include media(">=desktop-wide") {
          margin: 0 0 0 5px;
          width: 19px;
          height: 19px;
        }

        @include media(">=desktop-wide") {
          margin: 0 0 0 6px;
          width: 21px;
          height: 21px;
        }

        @include media(">=widescreen") {
          width: 24px;
          height: 24px;
        }
      }
    }

    .btn {
      background: #e9ecf4;
      color: $black;

      &:hover {
        background: darken(#e9ecf4, 5%);
      }

      &.disable {
        opacity: 0.7;
        pointer-events: none;
        background: #e9ecef;
        font-size: 11px;

        > * {
          opacity: 0.7;
        }
      }
    }
  }
}

.tab-list-info {
  @extend %listreset;
  font-size: 16px;
  line-height: calc(21 / 18);

  @include media(">=phone") {
    font-size: 18px;
  }

  @include media("<desktop-wide") {
    position: absolute;
    top: 3px;
    right: 0;
  }

  @include media(">=desktop-wide") {
    display: block;
    text-align: center;
    margin: 0;
  }

  @include media(">=widescreen") {
    margin: 6px 0 0;
  }

  > li {
    @include media("<desktop-wide") {
      text-align: right;
    }

    &:not(:last-child) {
      margin: 0 0 20px;

      @include media(">=phone") {
        margin: 0 0 39px;
      }

      @include media(">=desktop-wide") {
        margin: 0 0 20px;
      }

      @include media(">=widescreen") {
        margin: 0 0 25px;
      }
    }
    &:nth-child(1) {
      @include media(">=desktop-wide") {
        margin: 0 0 32px;
      }

      @include media(">=widescreen") {
        margin: 0 0 38px;
      }
    }
  }

  .difficulty {
    display: flex;
    align-items: flex-end;

    @include media(">=desktop-wide") {
      justify-content: center;
      font-size: 15.2717px;
      line-height: 23px;
    }

    @include media(">=widescreen") {
      font-size: 18px;
      line-height: 27px;
    }

    strong {
      margin: 0 14px 0 0;

      @include media(">=desktop-wide") {
        margin: 0 12px 0 0;
      }
    }

    img {
      width: 46px;
      height: 29px;
      transform: translate(0, -4px);
      pointer-events: none;

      @include media(">=desktop-wide") {
        width: 35.35px;
        height: 22.39px;
        transform: translate(-5px, -6px);
      }

      @include media(">=widescreen") {
        width: 41.67px;
        height: 26.39px;
      }
    }
  }

  p {
    font-size: 16px;
    line-height: calc(21 / 18);
    margin-bottom: 2px;

    @include media(">=phone") {
      font-size: 18px;
    }

    @include media(">=desktop-wide") {
      font-size: 11.878px;
      line-height: 14px;
      margin-bottom: 3px;
    }

    @include media(">=widescreen") {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 4px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.tab-price-cta {
  margin: 28px auto 15px;

  @include media("<desktop-wide") {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
  }

  @include media(">=desktop-wide") {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 40px 0 0;
  }

  @include media(">=desktop-wide") {
    order: -1;
    margin: 0 auto 20px;
  }

  .price-wrap {
    @include media(">=desktop-wide") {
      width: 169px;
      margin: 18px 0 0;
      order: 1;
      text-align: center;
    }

    @include media(">=desktop-wide") {
      width: 100%;
    }
  }

  .price {
    font-size: 36px;
    line-height: 1;
    margin: 0 0 5px;

    @include media(">=desktop-wide") {
      font-size: 30px;
      margin: 0 0 -1px;
    }

    @include media(">=widescreen") {
      font-size: 36px;
      margin: 0 0 3px;
    }
  }

  .price-text {
    font-size: 16px;
    line-height: 21px;
    color: #666768;
    margin: 0 0 18px;

    @include media(">=desktop-wide") {
      font-size: 12px;
      line-height: 18px;
    }

    @include media(">=widescreen") {
      margin: 0 0 28px;
    }
  }

  .btn {
    // @include media("<desktop-wide") {
    //   padding: 21px 64px;
    //   margin: 0 0 28px;
    // }

    // &.btn-light-green,
    // &.btn-locked {
    //   min-width: auto;
    //   font-size: 18px;
    //   line-height: 19px;
    //   font-weight: 700;
    //   letter-spacing: normal;
    //   cursor: pointer;

    //   @include media("<desktop-wide") {
    //     min-width: 215px;
    //   }

    //   @include media(">=desktop-wide") {
    //     font-size: 14px;
    //     line-height: 18px;
    //     padding: 13.6705px 42.5304px;
    //     margin: 0;
    //   }

    //   @include media(">=widescreen") {
    //     font-size: 16px;
    //     line-height: 20px;
    //     padding: 16.1128px 50.1286px;
    //     border-radius: 33.1994px;
    //   }
    // }
    // &.btn-light-green {
    //   @include media(">=desktop-wide") {
    //     padding: 13.6705px 42.5304px;
    //   }

    //   @include media(">=widescreen") {
    //     padding: 16.1128px 50.1286px;
    //   }
    // }
    // &.btn-locked {
    //   @include media(">=desktop-wide") {
    //     padding: 13.1705px 42.5304px;
    //   }

    //   @include media(">=widescreen") {
    //     padding: 16.1128px 50.1286px;
    //   }
    // }
  }
}
