$v-success: #28a745;
$v-error: #dc3545;

.form-validation {
  .v-text {
    display: none;
    text-align: left;
    margin-top: -10px;
  }

  &.success {
    .v-text {
      &.success {
        display: block;
        color: $v-success;
      }
    }

    input {
      border: 1px solid $v-success;
    }
  }

  &.error {
    .v-text {
      &.error {
        display: block;
        color: $v-error;
      }
    }

    .full-input {
      border-color: red;
    }

    input {
      border: 1px solid $v-error;
    }
  }
}
