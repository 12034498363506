@import "../../assets/styles/all.scss";

.pagination {
  &.indication {
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-top: 16px;

    .indicator {
      padding: 4px;
      background-color: white;
      cursor: pointer;

      &.previous {
        margin-right: 8px;
      }
    }
  }
}
