#header {
  background: $ct-blue;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1020;

  .container {
    max-width: none;
    padding: 0 15px;

    @include media(">=phone") {
      padding: 0 10%;
    }

    @include media(">=desktop-wide") {
      max-width: 1000px;
    }

    @include media("<=widescreen") {
      max-width: 1400px;
    }

    @include media(">=widescreen") {
      max-width: 1400px;
    }
  }

  &.header-dashboard {
    .container {
      padding: 0 60px;

      @include media("<desktop-wide") {
        padding: 0 40px;
      }

      @include media(">widescreen-sm") {
        padding: 0;
      }
    }

    .logo {
      max-width: 170px;
      height: 80px;

      img {
        width: 152px;
      }
    }
  }
}

.header-holder {
  display: flex;
  align-items: center;
}

.logo {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 143.16px;
  // height: 85px;
  // background: #08504D;
  // box-shadow: 0 7.06257px 14.1251px rgba(0, 0, 0, 0.15);
  // border-radius: 0 0 8.82822px 8.82822px;
  margin: 0 auto 0 0;
  position: relative;
  z-index: 2;

  @include media(">=desktop-wide") {
    width: 152px;
    height: 80px;
    margin: 0 60px 0 0;
  }

  @include media(">=widescreen") {
    width: 160px;
    height: 95px;
    // box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
    // border-radius: 0 0 10px 10px;
    margin: 0 60px 0 0;
  }

  img {
    width: 87px;

    @include media(">=desktop-wide") {
      width: 81px;
    }

    @include media(">=widescreen") {
      width: 98px;
    }
  }
}

#nav {
  @include media(">=desktop-wide") {
    display: flex;
    flex-grow: 1;
    padding: 0 5px 0 0;
  }

  @include media(">=widescreen") {
    padding: 0;
  }
}

.nav-drop {
  @include media("<desktop-wide") {
    background: $ct-dark-blue;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    overflow-y: auto;
    transition: opacity 0.27s ease-in-out, visibility 0.27s ease-in-out;
  }

  @include media(">=desktop-wide") {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  &.active {
    @include media("<desktop-wide") {
      opacity: 1;
      visibility: visible;
    }
  }

  .btn-outline-warning {
    @include media("<desktop-wide") {
      display: block;
      min-width: auto;
      font-size: 16px;
      // line-height: calc(16/14);
      line-height: 1.16;
      font-weight: 400;
      color: rgba(#f8f9fa, 0.7);
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #283636;
      padding: 25.5px;
      letter-spacing: 0.07em;
    }

    &:hover {
      @include media("<widescreen") {
        color: rgba(#f8f9fa, 1);
        background: transparent;
      }
    }
  }
}

.primary-menu {
  @extend %listreset;
  font-size: 16px;
  // line-height: calc(14/12);
  line-height: 1.16;
  letter-spacing: 0.07em;
  text-align: center;

  @include media(">=desktop-wide") {
    font-size: 14px;
    line-height: calc(16 / 14);
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    margin: 4px auto 0 0;
  }

  @include media(">=widescreen") {
    margin: 0 auto 0 0;
  }

  > li {
    &:not(:last-child) {
      margin: 0 0 -1px;
      @include media(">=desktop-wide") {
        margin: 0 43px 0 0;
      }
    }

    &.active {
      > a {
        color: $warning;

        &:hover,
        &:focus {
          color: $warning;
        }
      }
    }

    > a {
      display: block;
      color: rgba(#f8f9fa, 0.7);
      border-top: 1px solid #283636;
      border-bottom: 1px solid #283636;
      // padding: 18px;
      padding: 25px;

      @include media(">=desktop-wide") {
        display: inline-block;
        padding: 0;
        border: none;
      }

      &:hover,
      &:focus {
        color: rgba(#f8f9fa, 1);
      }
    }
  }
}

.nav-opener {
  display: block;
  width: 26px;
  height: 36px;
  position: relative;
  overflow: hidden;
  z-index: 2;

  @include media(">=desktop-wide") {
    display: none;
  }

  &.active {
    span {
      opacity: 0;
    }
    &:before,
    &:after {
      transform: rotate(45deg);
      top: 50%;
      left: 0;
      right: 0;
    }
    &:after {
      transform: rotate(-45deg);
    }
  }

  span,
  &:before,
  &:after {
    background: $white;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 3px;
    transition: all 0.3s linear;
    margin-top: -2px; /* height/2 */
  }
  &:before,
  &:after {
    content: "";
    top: 30%;
  }
  &:after {
    top: 70%;
  }
}

.user-info {
  display: flex;
  align-items: center;
  margin-left: auto;
  justify-content: center;
  flex-direction: column;

  @include media(">=desktop-wide") {
    flex-direction: row;
  }

  @include media("<desktop-wide") {
    padding: 20px;
  }

  .item {
    width: 100%;
    padding: 25px;
    border-top: 1px solid #283636;
    border-bottom: 1px solid #283636;
    text-align: center;

    @include media(">=desktop-wide") {
      padding: 0;
      border: 0;
    }

    + .item {
      margin-bottom: -1px;

      @include media(">=desktop-wide") {
        margin-left: 35px;
      }
    }
  }

  .user-avatar {
    background-color: $ct-light-blue;
    color: $ct-blue;
    border-radius: 50%;
    height: 40px;
    text-align: center;
    width: 40px;
    margin-right: 30px;

    .initials {
      font-size: calc(16px);
      font-weight: bold;
      line-height: 1;
      position: relative;
      top: calc(40px / 4); /* 25% of parent */
    }

    &:hover {
      background-color: #8b9aae;
      color: $ct-light-blue;
    }

    @include media("<desktop-wide") {
      margin: 30px auto;
    }
  }

  .btn-login {
    justify-content: center;
  }
}

.admin-navbar {
  background: $white;
  border-bottom: 1px solid $gray-300;

  .container {
    @extend .container;
    margin: 12px auto;
    max-width: 870px;

    display: flex;
    flex-direction: row;
    vertical-align: middle;
    gap: 10px;

    @include media("<desktop-wide") {
      flex-direction: column;
    }

    @include media(">widescreen-sm") {
      max-width: 1440px;
    }

    a {
      padding: 8px 12px;
      color: $ct-dark-grey;
    }   

    .active {
      background: $base-background-color;
      border: 1px solid $gray-300;
      border-radius: 6px;
    }
  }
}
