.share-popup {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  overflow: auto;

  &.popup-show {
    display: block;
  }

  .inner {
    position: absolute;
    padding: 40px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
    margin: auto;
    background: #ffffff;
    border: 1px solid #ebf2f8;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
    border-radius: 30px;
    max-height: 75%;
    overflow: auto;

    // @include media('>=desktop') {
    // 	max-height: none;
    // 	overflow: inherit;
    // }

    // @include media('>=tablet') {
    // 	padding: 57px 48px;
    // }

    @include media(">=desktop") {
      max-width: 835px;
    }
  }

  .close {
    cursor: pointer;

    svg {
      margin-left: 10px;
    }
  }

  .p-list {
    @extend %listreset;

    li {
      display: flex;
      align-items: center;
      text-align: left;
      border: 1px solid #ebf2f8;
      margin-top: -1px;

      &.child-input-focused {
        background: #fcfbfb;
      }

      &:first-child {
        background: #ffffff;
        border: 1px solid #dee2e6;
        font-size: 12px;
        line-height: 18px;
        color: #6c757d;
        margin-top: 0;

        .col-1 {
          padding: 10px;

          @include media(">=tablet") {
            padding: 21px 10px 21px 22px;
          }
        }

        .col-2 {
          padding: 10px;

          @include media(">=tablet") {
            padding: 10px 15px;
          }

          span {
            color: #6c757d;
          }
        }

        .col-3 {
          @include media(">=tablet") {
            padding-right: 30px;
          }
        }
      }

      .btn {
        margin-bottom: 0;
        font-weight: 700;
        font-size: 9px;
        line-height: 18px;
        min-width: auto;
        padding: 2px 5px;

        @include media(">=phone") {
          font-size: 10px;
          padding: 2px 11px;
        }
      }

      .col-1 {
        width: 85px;
        flex: 0 0 85px;
        padding: 10px;

        @include media(">=phone") {
          width: 150px;
          flex: 0 0 auto;
        }

        @include media(">=tablet") {
          width: 250px;
          padding: 11px 22px;
        }

        .img {
          display: flex;
          align-items: center;
          font-size: 13px;
          line-height: 18px;
          color: #343a40;

          span {
            display: none;
            margin-left: 13px;

            @include media(">=phone") {
              display: block;
            }
          }

          a {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            max-width: 37px;
            flex: 0 0 37px;
            height: 37px;
            overflow: hidden;
          }
        }
      }

      .col-2 {
        flex: 1 0 0;
        padding: 10px;

        @include media(">=tablet") {
          padding: 10px 15px;
        }

        span {
          font-size: 12px;
          line-height: 18px;
          color: #000000;
        }
      }

      .col-3 {
        padding: 10px;

        @include media(">=tablet") {
          padding-right: 20px;
        }
      }
    }
  }

  &.form-share-popup {
    .inner {
      @include media(">=tablet") {
        max-width: 540px;
        padding: 32px 56px 46px 58px;
      }
    }

    .close {
      display: inline-block;
      font-weight: 700;
      font-size: 16px;
      margin-bottom: 20px;
      width: 100%;
      text-align: center;

      @include media(">=tablet") {
        margin-bottom: 40px;
      }

      svg {
        width: 11px;
        height: 11px;
        margin-left: 10px;
      }
    }

    .popup-title {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 22px;
      color: #000000;
      margin-bottom: 15px;

      @include media(">=tablet") {
        margin-bottom: 29px;
        font-size: 16px;
      }

      span {
        display: block;
        margin-right: 10px;
        background: #ededed;
        border-radius: 12px;
        font-size: 12px;
        line-height: 18px;
        padding: 1px 10px;

        @include media(">=tablet") {
          margin-right: 18px;
        }
      }
    }

    .text {
      margin: 0;
      font-size: 14px;
      line-height: 20px;
      color: #787878;
      max-width: none;
      margin-bottom: 20px;

      @include media(">=tablet") {
        margin-bottom: 35px;
      }

      p {
        margin-bottom: 0;
      }
    }

    .block-ms {
      background: rgba(0, 123, 255, 0.1);
      border: 1px solid #007bff;
      border-radius: 10px;
      text-align: left;
      padding: 15px;
      margin-bottom: 20px;

      @include media(">=tablet") {
        padding: 20px 17px;
        margin-bottom: 42px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &.success {
        background: rgba(41, 137, 82, 0.1);
        border: 1px solid #298952;
        border-radius: 10px;
        padding: 17px 17px;

        strong {
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #298952;
          margin-bottom: 0;

          @include media(">=tablet") {
            font-size: 16px;
          }
        }

        .label {
          display: block;
          background: #298952;
          border-radius: 12px;
          font-weight: 700;
          font-size: 10px;
          line-height: 1;
          padding: 2px 10px;
          margin-right: 17px;
          color: #fff;
        }
      }

      strong {
        display: flex;
        justify-content: flex-start;
        font-size: 14px;
        color: #007bff;
        margin-bottom: 9px;

        @include media(">=tablet") {
          font-size: 16px;
          line-height: 18px;
        }

        svg {
          margin-right: 14px;
        }
      }

      p {
        margin-left: 31px;
        margin-bottom: 0;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #787878;
      }
    }
  }
}

.p-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 35px;

  strong {
    font-size: 14px;
    line-height: 24px;
    padding-right: 10px;
    text-align: left;

    @include media(">=phone") {
      padding-right: 15px;
      font-size: 16px;
    }
  }

  .close {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 16px;
    text-transform: capitalize;
  }
}
