@import "../../assets/styles/all.scss";

.admin-section-title {
  &.recent-activity {
    .activity-heading {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .view-activities {
        font-size: 14px;
        cursor: pointer;
      }
    }

    .loading-spinner {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
