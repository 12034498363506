.modal-dialog {
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  margin: auto;
  width: 100vw;

  .modal-content {
    width: fit-content;
    overflow-y: auto;
    margin: 0;
    
    // @include media(">widescreen-sm") {
    //   width: 1440px;
    // }
  }

  .modal-header {
    .modal-title {
      margin: 0;
    }

    .btn-close {
      &:hover {
        background: transparent
          url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e")
          center/1em auto no-repeat;
      }
    }
  }

  .modal-content {
    p {
      margin: 0;
    }
  }

  .modal-footer {
    .btn.btn-primary {
      background: $success;
    }
  }
}
