@import "../../assets/styles/all.scss";

.auth-super-container {
    .maintenance {
        background: none;
        border: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;

        img {
            width: 164px;
            pointer-events: none;
        }

        .heading2 {
            color: $white;
            font-size: 30px;
            font-weight: bold;
            margin: 48px auto 30px;
            line-height: 36px;
        }

        p {
            font-size: 14px;
            color: $white;
            line-height: 20px;   
            text-align: center;         
        }

        .logo {
            margin: 50px auto;
            pointer-events: none;
        }
    }
}
