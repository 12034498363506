@font-face {
  font-family: "icomoon";
  src: url("../../../fonts/icomoon/icomoon.eot?bpdzkg");
  src: url("../../../fonts/icomoon/icomoon.eot?bpdzkg#iefix")
      format("embedded-opentype"),
    url("../../../fonts/icomoon/icomoon.ttf?bpdzkg") format("truetype"),
    url("../../../fonts/icomoon/icomoon.woff?bpdzkg") format("woff"),
    url("../../../fonts/icomoon/icomoon.svg?bpdzkg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-angle-left:before {
  content: "\e900";
}
.icon-angle-right:before {
  content: "\e901";
}
