.btn {
  @include button-color(
    $btn-background,
    $btn-color,
    $btn-border,
    $btn-hover-background,
    $btn-hover-border,
    $btn-hover-color
  );
  border: $btn-border;
  border-radius: $btn-border-radius;
  display: inline-block;
  font-size: $btn-font-size;
  line-height: $btn-line-height;
  padding: $btn-padding;
  vertical-align: top;
  text-align: center;
  font-weight: 700;
  transition: color 0.27s ease-in-out, background-color 0.27s ease-in-out;

  &-default {
    @include button-color(
      $btn-background,
      $btn-color,
      $btn-border,
      $btn-hover-background,
      $btn-hover-border,
      $btn-hover-color
    );
  }

  &-primary {
    @include button-color(
      $btn-background-primary,
      $btn-color-primary,
      $btn-border-primary,
      $btn-hover-background-primary,
      $btn-hover-border-primary,
      $btn-hover-color-primary
    );
  }

  &.btn-warning {
    font-size: 16px;
    line-height: calc(18 / 16);
    font-weight: bold;
    color: $white;
    background: $ct-green;
    border-radius: 30px;
    padding: 15px 45px;
    transition: background-color 0.27s ease-in-out;

    @include media(">=desktop-wide") {
      font-size: 14px;
      line-height: calc(16 / 14);
      padding: 11.7708px 32.3125px;
    }

    @include media(">=widescreen") {
      font-size: 16px;
      line-height: calc(18 / 16);
      border-radius: 30px;
      padding: 15px 45px;
    }

    &:hover,
    &:active {
      background-color: #0f6623;
      color: $white;
    }
  }

  &.btn-warning-gd {
    font-size: 14px;
    line-height: calc(20 / 14);
    font-weight: bold;
    color: $ct-blue;
    background: #edf4ff;
    border-radius: 30px;
    padding: 11px 28px;
    transition: background-color 0.27s ease-in-out;
    border: 1px solid #cfdeff;
    margin: 0;

    &:hover {
      background-color: $ct-blue;
      color: $white;
    }
    &:focus {
      outline: 1px dotted $ct-blue-links;
      outline: 5px auto -webkit-focus-ring-color;
    }
  }

  &.clear-btn {
    background: none;
    color: $ct-blue-links;
    margin-top: 6px;

    &:hover {
      background: none;
      color: $ct-blue;
    }
  }

  &.btn-multi-select {
    font-size: 14px;
    line-height: calc(20 / 14);
    font-weight: bold;
    color: $ct-blue;
    background: #edf4ff;
    border-radius: 30px;
    padding: 8px 20px;
    transition: background-color 0.27s ease-in-out;
    border: 1px solid #cfdeff;
    margin: 8px 8px 16px 8px;

    &.selected {
      background-color: $ct-blue;
      color: $white;
    }
  }

  &.btn-admin-settings {
    font-size: 14px;
    line-height: calc(20 / 14);
    font-weight: normal;
    color: $ct-blue-links;
    background: none;
    border-radius: 0;
    padding: 0;
    transition: none;
    border: none;
    margin: 6px 0;

    &:hover {
      background-color: none;
      color: $ct-dark-blue;
    }

    &:focus {
      outline: 1px dotted #212121;
      outline: 5px auto -webkit-focus-ring-color;
    }
  }

  &.btn-header {
    font-size: 12px;
    line-height: calc(18 / 16);
    font-weight: 700;
    color: $ct-light-blue;
    background: transparent;
    border-radius: 30px;
    padding: 11px 28px;
    border: 2px solid $ct-light-blue;
    transition: background-color 0.27s ease-in-out;

    &:hover {
      background: $ct-light-blue;
      color: $ct-dark-blue;
    }
  }

  &.btn-locked {
    display: inline-flex;
    align-items: center;
    min-width: auto;
    font-size: 12px;
    line-height: 13px;
    padding: 10.4565px 31.3695px;
    font-weight: 700;
    color: $white;
    background: #6c757d;
    border-radius: 30px;
    // pointer-events: none;
    cursor: not-allowed;

    @include media(">=phone") {
      font-size: 17px;
      line-height: calc(18 / 17);
      padding: 15px 45px;
    }

    @include media(">=tablet") {
      font-size: 16px;
      line-height: calc(18 / 16);
    }

    @include media(">=desktop") {
      font-size: 17px;
      line-height: calc(18 / 17);
      padding: 12px 45px;
    }

    &:hover,
    &:focus {
      background: #343a40;
      color: #fff;
    }

    svg {
      margin: 0 0 0 10px;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  &-sm {
    border-radius: $btn-border-radius-sm;
    padding: 4px 10px;
  }

  &-orange {
    background: #f2994a;
    color: $white;

    &:hover {
      background: $orange;
      color: $white;
    }
  }
}

.btn-outline-warning {
  display: inline-block;
  min-width: 72px;
  font-size: 14px;
  line-height: calc(12 / 11);
  font-weight: 700;
  text-align: center;
  color: $warning;
  background-color: transparent;
  border: 2px solid $warning;
  border-radius: 30px;
  padding: 0.546em 1.5em; // padding: .546em .5em;
  transition: color 0.27s ease-in-out, background-color 0.27s ease-in-out;

  @include media(">=desktop-wide") {
    min-width: 76px;
    padding: 0.637em 1.5em; // padding: .637em .5em;
  }

  @include media(">=widescreen") {
    min-width: 93px;
    font-size: 16px;
    line-height: calc(16 / 14);
    padding: 0.572em 1.5em; // padding: .572em .5em;
  }

  &:hover {
    color: #041d1c;
    background-color: #cadd5d;
    border-color: #cadd5d;
  }
}

.btn-light-green {
  font-size: 18px;
  line-height: calc(24 / 18);
  border-radius: 30px;
  background-color: $ct-blue;
  padding: 18.8217px 58.5563px;
  color: $white;

  @include media("≥desktop-wide") {
    font-size: 14px;
    padding: 13.6705px 42.5304px;
    border-radius: 22.7841px;
  }

  @include media("≥widescreen") {
    font-size: 16px;
    line-height: calc(23 / 16);
    padding: 18px 56px;
    border-radius: 30px;
    margin-bottom: 30px;
  }

  &:hover,
  &:active,
  &:focus,
  &:focus-visible {
    // background-color: #1C8334;
    background-color: $ct-blue-links;
    color: $white;
  }
}

.btn-green {
  font-size: 16px;
  line-height: calc(18 / 16);
  color: $warning;
  background: $green;
  transition: background-color 0.27s ease-in-out;
  border-radius: 30px;
  padding: 15px 45px;

  @include media(">=desktop-wide") {
    min-width: 195px;
    font-size: 14px;
    line-height: calc(16 / 14);
    padding: 15px 45px;
  }

  @include media(">=widescreen") {
    min-width: 206px;
    font-size: 16px;
    line-height: calc(18 / 16);
  }

  &:hover,
  &:focus {
    background-color: #043836;
    color: $warning;
  }
}

.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 21px;
    height: 21px;
    margin: 0 10px 0 0;
  }
}

.btn-login {
  display: flex;
  align-items: center;
  color: $white;
  font-weight: 700;

  &:hover {
    color: #d3d3d3;
  }

  svg {
    margin-right: 12px;
  }
}
