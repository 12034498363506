.account-certification-container {
  background: #f9f9f9;
}

.acc-tabs {
  padding-bottom: 56px;
  // padding-top: 35px;

  // @include media('>=desktop-wide') {
  // 	padding-bottom: 56px;
  // 	padding-top: 35px;
  // }

  .acc-tabs-content {
    &.desktop {
      display: none;
      .acc-tabs-item {
        display: none;

        &.active {
          display: block;
        }

        &.inactive {
          display: none;
        }
      }
    }

    &.mobile {
      display: block;
    }

    @include media(">=desktop-wide") {
      &.desktop {
        display: block;
      }

      &.mobile {
        display: none;
      }
    }
  }

  .card-compl {
    border-radius: 0;
    border: 1px solid #dee2e6;
    box-shadow: none;
    background-color: $white;
  }

  .tabset-nav {
    @extend %listreset;

    display: none;
    justify-content: space-between;
    padding: 0 10px;
    background: $ct-dark-blue;
    border: 1px solid #051525;
    color: $white;
    font-size: 16px;
    line-height: 1.4;

    @include media(">=desktop-wide") {
      display: flex;
      padding: 0 25px;
      font-size: 16px;
      justify-content: flex-start;
    }

    li {
      margin-bottom: -2px;
      + li {
        margin-left: 10px;

        @include media(">=phone") {
          margin-left: 20px;
        }

        @include media(">=desktop") {
          margin-left: 60px;
        }
      }
    }

    a {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      color: inherit;
      padding: 20px 0;

      &:before {
        display: block;
        content: attr(title);
        font-weight: bold;
        height: 0;
        overflow: hidden;
        visibility: hidden;
      }

      &.active {
        font-weight: 700;

        &:after {
          background: $ct-blue-links;
          transform: translateY(-1px);
        }
      }

      &:after {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        content: "";
        height: 5px;
        background: transparent;
        transition: 0.3s all;
        transform: translateY(-5px);
      }
    }
  }

  .opener {
    position: relative;
    display: flex;
    padding: 20px 27px;
    font-size: 16px;
    justify-content: flex-start;
    background: $ct-dark-blue;
    border: 1px solid #051525;
    color: $white;
    line-height: 1.4;

    @include media(">=desktop-wide") {
      display: none;
    }

    &:after {
      position: absolute;
      content: "";
      top: 50%;
      transform: translateY(-50%);
      width: 16px;
      height: 10px;
      background-image: url("../../../images/arrow-down-white.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      right: 10px;
    }
  }

  .card-compl {
    @include media(">=desktop-wide") {
      padding: 50px 35px 20px 38px;
    }

    .main-heading {
      display: flex;
      align-items: center;
      font-size: 18px;

      @include media(">=tablet") {
        margin-bottom: 45px;
      }

      svg {
        margin-right: 15px;
      }
    }

    .card-lvl {
      margin-top: 35px;
    }

    .custom-table-holder {
      @include media(">=desktop-wide") {
        width: 385px;
      }
    }
  }

  a {
    cursor: pointer;
  }
}
