@import "../../assets/styles/all.scss";

.important-tag-container {
  padding: 0 20px;
  border-radius: 10px;
  width: fit-content;

  &.info-container {
    background-color: $lightGreen;
    border: 1.5px solid #c9e16b;
  }

  &.alert-container {
    background-color: $lightRed;
    border: 1.5px solid #fb7655;
  }

  .icon {
    margin: 20px 20px 20px 0;
  }

  .message {
    color: $gray;
  }

  .info-link {
    color: $green;
  }

  .alert-link {
    color: $red;
  }

  .message-item {
    margin: 0;
    padding: 0;
    line-height: 1.25;
  }
}
