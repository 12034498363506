@import "../../assets/styles/all.scss";

.text-badge-container {
  background: $bgGray;
  border: 0.5px solid #ced4da;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
  border-radius: 20px;
  color: $textGray;
  width: fit-content;
  height: fit-content;
  padding: 4px 10px;
}
