.steps-accordion {
  .acc-item {
    border: 1px solid #B7C8D8;
    margin-bottom: -1px;
    background: #F1F5F9;
    border-radius: 0;

    &.cert-step-0 {
      border-color: #BEBEBE;
    }

    &.done {
      border-color: #28a745;
      //  z-index: -1;

      .acc-header {
        background: #ebffef;
      }

      ellipse {
        fill: #cde2d1;
      }

      path {
        fill: #28a745;
      }
    }    

    // &:not(:first-child) {
    //   margin-top: -1px;
    // }

    // // .step-block {
    // //   padding: 40px;

    // //   @include media('<desktop-wide') {
    // //   	padding-top: 59px;
    // //   	padding-bottom: 52px;
    // //   }
    // // }
  }

  .acc-header {
    display: flex;
    align-items: center;
    background-color: $white;
    padding: 20px 38px;    
    cursor: pointer;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.025);

    svg {
      margin-right: 17px;
    }

    h3 {
      margin-bottom: 0;
      font-size: 18px;
      color: #343a40;
    }
  }
}

.card-header,
.card-body {
  padding: 0;
  cursor: pointer;
}

.card-header {
  border-bottom: 0;
}
