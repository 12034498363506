[id="wrapper"] {
	width: 100%;
	overflow: hidden;
	background: $base-background-color;
}

.container {
	max-width: $max-width-center-container;
	padding: $padding-container;
	margin: 0 auto;

	&.sm {

		@include media('>=phone') {
			max-width: 394px;
		}

		@include media('>=tablet') {
			max-width: 604px;
		}

		@include media('>=desktop') {
			max-width: 826px;
		}

		@include media('>=desktop-wide') {
			max-width: 880px;
		}

		@include media('>=widescreen') {
			max-width: 1026px;
		}
	}

	&-md {
		max-width: 449px;
		padding: 0 15px;
		margin: 0 auto;

		@include media('>=desktop-wide') {
			max-width: $container-md;
		}
	}
}

.bg-primary {
	background-color: $dark-green;
}

.bg-secondary {
	background-color: $light-bg;
}

mark {
	background-color: transparent;
	color: $ct-blue-links;
}

.is-hide {
	display: none !important;
}

.js-tab-hidden {
	display: block !important;
	left: -9999px !important;
	position: absolute !important;
	top: -9999px !important;
}
