$cscauGreen: #08504d;
$footer: #043836;
$copyright: rgba(222, 226, 230, 0.5);
$white: white;
$red: red;
$green: green;
$footerInfo: rgba(255, 255, 255, 0.6);
$banner: #135754;
$nearBlack: #212529;
$secondary: #6c757d;
$asterisk: #dc3545;
$dark: #343a40;
$lightGreen: rgba(201, 225, 107, 0.1);
$lightRed: rgba(251, 118, 85, 0.1);
$gray: #857f7f;
$bgGray: #e9ecef;
$textGray: #868e96;
$inactiveGray: #e9ecef;
$light: #f8f9fa;
$success: #28a745;
$lightCyan: #ebf2f8;
$orangeish: #f2994a;
$ct-blue: #0a41b2;
$ct-dark-blue: #092645;
$ct-light-blue: #f3f8ff;
$ct-blue-links: #2078ec;
$ct-green: #28a745;
$ct-grey: #dfdfdf;
$ct-dark-grey: #20272e;
$ct-text-grey: #6c757d;
$ct-light-grey: #f1f5f9;
