.copyright {
	font-family: Arial;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 17px;
}

.heading1 {
	font-family: Arial;
	font-style: normal;
	font-weight: 700;
	font-size: 30px;
	line-height: 34px;
}

.heading2 {
	font-family: Arial;
	font-style: normal;
	font-weight: 700;
	font-size: 25px;
	line-height: 29px;
}

.heading3 {
	font-family: Arial;
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 24px;
}

.heading4 {
	font-family: Arial;
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 30px;
}

.heading5 {
	font-family: Arial;
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 16px;
}

.normal1 {
	font-family: Arial;
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 30px;
}

.normal2 {
	font-family: Arial;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 30px;
}

.normal3 {
	font-family: Arial;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
}

.normal4 {
	font-family: Arial;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
}
