.overlay {
  background: rgba(255, 255, 255, 0.6);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 999;
  // margin-top: -80px;
  display: flex;
  align-items: center;
  justify-content: center;

  .json-spinner,
  .lds-ring {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .json-spinner {
    #lottie {
      svg {
        width: 150px !important;
        height: 150px !important;
      }
    }
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 16px;
  height: 16px;
  margin-right: 4px;

  &.black {
    div {
      border-color: black transparent transparent transparent !important;
    }
  }

  &.blue {
    div {
      border-color: #0a41b2 transparent transparent transparent !important;
    }
  }

  &.large {
    width: 40px !important;
    height: 40px !important;

    div {
      width: 36px !important;
      height: 36px !important;
    }
  }
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 12px;
  height: 12px;
  margin: 2px;
  border: 2px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
