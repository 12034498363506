@import "../../assets/styles/all.scss";

[data-tooltip] {
    position: relative;
    cursor: help;
  }
  
  [data-tooltip]::after {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    content: attr(data-tooltip);
    margin: 0 auto;
    left: 0;
    right: 0;
    top: -50px;
    border-radius: 3px;
    box-shadow: 0 0 5px 2px rgba(156, 155, 155, 0.3);
    background-color: white;
    z-index: 10;
    padding: 8px;
    width: 115px;
    transform: translateY(-50px);
    transition: all 150ms cubic-bezier(0.25, 0.8, 0.25, 1);
    color: $ct-blue-links;
    text-align: center;
    font-size: 12px;
  }
  
  [data-tooltip]:hover::after {
    opacity: 1;
    transform: translateY(0);
    transition-duration: 300ms;
  }