@import "../../assets/styles/all.scss";

.full-width {
	@include media(">widescreen-sm") {
		width: 1410px !important;
	}
}

.page-link {
	display: inline;
}
