@import "../assets/styles/all.scss";

.searchResults {
  .account-search-res {
    cursor: pointer;

    &.selectable {
      background-color: none;

      &.selected {
        background-color: #afb9ca;
      }
    }
  }
}

.setup-code {
  overflow-wrap: break-word;
}

.partner-option {
  cursor: pointer;
  color: #0035f1;
}
