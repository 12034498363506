.form-section {
  padding: 148px 0 111px;
  background-color: #eaf3f3;

  @include media(">=desktop-wide") {
    padding: 157px 0 145px;
  }

  @include media(">=widescreen") {
    padding: 151px 0 134px;
  }

  &.success-mod {
    padding: 94px 0 96px;

    @include media(">=desktop-wide") {
      padding: 87px 0 79px;
    }

    @include media(">=widescreen") {
      padding: 110px 0 112px;
    }

    .form-section-body {
      max-width: 370px;
      margin: 0 auto 90px;

      @include media(">=desktop-wide") {
        margin: 7px 0 0 12px;
      }

      @include media(">=widescreen") {
        margin: 22px 0 0 48px;
      }

      .inner {
        max-width: 360px;
        margin: 0 auto;

        @include media(">=desktop-wide") {
          max-width: 295px;
          margin: 0;
        }

        @include media(">=widescreen") {
          max-width: none;
        }

        p {
          max-width: 340px;
          margin: 0 auto 2.2em;
          font-size: 22px;
          line-height: calc(26 / 22);

          @include media(">=phone") {
            max-width: 275px;
          }

          @include media(">=desktop-wide") {
            max-width: 340px;
            margin: 0 0 1.2em;
            font-size: 22px;
            line-height: calc(28 / 22);
          }

          @include media(">=widescreen") {
            max-width: 340px;
            margin: 0 0 1.45em;
            font-size: 28px;
            line-height: calc(36 / 28);
          }
        }
      }

      figure {
        @include media(">=desktop-wide") {
          transform: translate(-14px, 10px);
        }
      }
    }
  }

  &.create-account-mod {
    padding: 94px 0 96px;

    @include media(">=desktop-wide") {
      padding: 87px 0 79px;
    }

    @include media(">=widescreen") {
      padding: 110px 0 112px;
    }

    .form-section-body {
      margin: 0 auto 82px;

      @include media(">=desktop-wide") {
        margin: 5px 0 0 12px;
      }

      @include media(">=widescreen") {
        max-width: none;
        margin: 23px 0 0 48px;
      }

      .inner {
        @include media(">=widescreen") {
          // max-width: none;
        }
      }

      figure {
        @include media(">=desktop-wide") {
          transform: translate(-7px, 30px);
        }

        @include media(">=widescreen") {
          transform: translate(-17px, 30px);
        }

        img {
          @include media(">=widescreen") {
            width: 361px;
          }
        }
      }

      h1 {
        margin: 0 0 1.2em;

        @include media(">=desktop-wide") {
          margin: 0 0 1.2em;
        }
      }

      p {
        max-width: 270px;
        margin: 0 auto 37px;
        font-size: 22px;
        line-height: calc(26 / 22);

        @include media(">=desktop-wide") {
          margin: 0 0 28px;
          font-size: 22px;
          line-height: calc(28 / 22);
        }

        @include media(">=widescreen") {
          max-width: 310px;
          font-size: 28px;
          line-height: calc(36 / 28);
        }
      }
    }

    .inner {
      max-width: 400px;
      margin: 0 auto;

      @include media(">=desktop-wide") {
        margin: 0;
      }
    }
  }

  &.login-mod {
    padding: 94px 0 96px;

    @include media(">=desktop-wide") {
      padding: 87px 0 79px;
    }

    @include media(">=widescreen") {
      padding: 110px 0 112px;
    }

    .form-section-body {
      margin: 0 auto 104px;

      @include media(">=desktop-wide") {
        margin: 6px 0 0 12px;
      }

      @include media(">=widescreen") {
        max-width: 360px;
        margin: 23px 0 0 48px;
      }
    }

    figure {
      margin: 0 -23px;
      transform: translate(-5px, 10px);

      @include media(">=desktop-wide") {
        margin: 0;
      }

      img {
        width: 100%;
      }
    }
  }

  .container {
    max-width: 880px;

    @include media(">=widescreen") {
      max-width: 1089px;
    }
  }

  .row {
    @include media(">=desktop-wide") {
      display: flex;
      margin: 0 -15px;
    }
  }

  .col {
    @include media(">=desktop-wide") {
      max-width: 50%;
      flex: 0 0 50%;
      // padding: 0 15px;
    }
  }
}

.form-section-body {
  max-width: 338px;
  font-size: 22px;
  line-height: calc(26 / 22);
  text-align: center;
  margin: 0 auto 91px;

  @include media(">=desktop-wide") {
    font-size: 24px;
    line-height: 32px;
    text-align: left;
    margin: 7px 0 0 12px;
  }

  @include media(">=widescreen") {
    font-size: 28px;
    line-height: 36px;
    margin: 9px 0 0 21px;
  }

  h1 {
    font-size: 48px;
    line-height: calc(42 / 48);
    margin: 0 0 1.2em;
    padding-top: 30px;

    @include media(">=desktop-wide") {
      font-size: 40px;
      line-height: calc(36 / 40);
      margin: 0 0 1.2em;
      padding-top: 55px;
    }

    @include media(">=widescreen") {
      font-size: 50px;
      line-height: calc(48 / 50);
      margin: 0 0 1.2em;
      padding-top: 30px;
    }
  }

  p {
    max-width: 340px;
    margin: 0 auto 2.2em;
    font-size: 22px;
    line-height: calc(26 / 22);

    @include media(">=phone") {
      max-width: 275px;
    }

    @include media(">=desktop-wide") {
      max-width: 340px;
      margin: 0 0 1.2em;
      font-size: 22px;
      line-height: calc(28 / 22);
    }

    @include media(">=widescreen") {
      max-width: 360px;
      margin: 0 0 1.45em;
      font-size: 28px;
      line-height: calc(36 / 28);
    }
  }

  figure {
    margin: 0 30px;
    transform: translate(-5px, 10px);

    @include media(">=desktop-wide") {
      margin: 0;
    }
  }
}

.form-card {
  max-width: 393px;
  font-size: 16px;
  line-height: 22px;
  background: #ffffff;
  border-radius: 29.7605px;
  margin: 0 auto;
  padding: 32px 24px;

  @include media(">=phone") {
    padding: 53px;
  }

  @include media(">=desktop-wide") {
    margin: 40px auto;
  }

  @include media(">=widescreen") {
    max-width: 466.12px;
    font-size: 20px;
    line-height: 26px;
    border-radius: 35.2973px;
    padding: 58px 64px 62px;
  }

  &.success-mod {
    h2 {
      margin: 0 0 21px;

      @include media(">=widescreen") {
        margin: 0 0 15px;
      }
    }

    p {
      margin: 0 0 36px;

      @include media(">=widescreen") {
        margin: 0 0 44px;
      }
    }

    .form-elements {
      .submit-row {
        margin: 58px 0 0;

        @include media(">=widescreen") {
          margin: 70px 0 0;
        }
      }
    }

    button {
      &.js-btn-abn-lookup {
        position: absolute;
        transform: translate(-57px, -6px);
        background: transparent;

        @include media(">=desktop-wide") {
          transform: translate(-58px, -6px);
        }

        @include media(">=widescreen") {
          transform: translate(-58px, -2px);
        }
      }
    }
  }

  &.create-account-mod {
    h2 {
      margin: 0 0 25px;

      @include media(">=widescreen") {
        margin: 0 0 21px;
      }
    }

    .abn-state {
      margin: 15px 0;

      @include media(">=widescreen") {
        margin: 14px 0 19px;
      }

      h4 {
        margin: 0 0 1px;

        @include media(">=widescreen") {
          font-size: 20px;
          line-height: 26px;
          margin: 0;
        }
      }

      h5 {
        margin: 0;

        @include media(">=widescreen") {
          font-size: 20px;
          line-height: 26px;
        }
      }
    }
  }

  h2 {
    display: flex;
    align-items: center;
    font-size: 28px;
    line-height: 20px;
    margin: 0 0 39px;

    @include media(">=widescreen") {
      font-size: 34px;
      line-height: 39px;
    }

    span {
      font-weight: 700;
      font-size: 30px;
      transform: scaleX(-1);
      margin: 0 0 0 10px;
    }
  }

  p {
    color: #6c757d;
  }

  .subheading {
    font-size: 14px;
    line-height: 20px;
    color: #6c757d;

    @include media(">=widescreen") {
      font-size: 16px;
      line-height: 22px;
    }
  }

  p.reset-subheading {
    padding-bottom: 20px;
  }

  p.verify-subheading {
    padding: 5px 0 20px 0;
  }

  .small-text {
    font-size: 12px;
    line-height: 20px;
    color: #6c757d;
    margin: 0 0 41px;

    @include media(">=widescreen") {
      font-size: 14px;
      line-height: 24px;
      margin: 0 0 49px;
    }

    a {
      color: inherit;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .abn-state {
    font-size: 12px;
    line-height: 20px;
    color: #7d6c6c;
    margin: 18px 0 0;

    h4 {
      font-size: 16px;
      line-height: 20px;
      color: #000;
      margin: 0 0 3px;
      text-transform: capitalize;
    }

    h5 {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #000;
    }

    p {
      margin: 0;
      color: inherit;

      a {
        color: inherit;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.form-elements {
  label {
    display: block;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #6c757d;
    // margin: 0 0 4px;
    margin: 0;

    @include media(">=widescreen") {
      font-size: 16px;
      line-height: 21px;
      margin: 0 0 5px;
    }
  }

  .required {
    color: #ff0000;
  }

  input[type="text"],
  input[type="email"],
  input[type="password"],
  select,
  .jcf-select {
    width: 100%;
    // height: 38.53px;
    background: #fcfbfb;
    border: 1px solid #dee2e6;
    // padding: 4px 8px;
    border-radius: 3.21053px;
    -webkit-appearance: none;
    -moz-appearance: none;

    @include media(">=widescreen") {
      height: 45.69px;
      border-radius: 3.80783px;
    }
  }

  input.code {
    width: 13%;
    height: 55px;
    background: #fcfbfb;
    border: 1px solid #dee2e6;
    padding: 4px 8px;
    margin: 0 2px;
    border-radius: 3.21053px;
    font-size: 30px;

    @include media(">=widescreen") {
      height: 45.69px;
      border-radius: 3.80783px;
    }
  }

  .form-row {
    &:not(:last-child) {
      margin: 0 0 25px;
    }
  }

  .pass-wrap {
    position: relative;

    input[type="text"] + .js-password-toggle {
      svg {
        &:nth-child(1) {
          display: none;
        }
        &:nth-child(2) {
          display: block;
        }
      }
    }

    .js-password-toggle {
      position: absolute;
      top: 50%;
      right: 14px;
      transform: translate(0, -50%);
      cursor: pointer;

      svg {
        &:nth-child(1) {
          display: block;
        }
        &:nth-child(2) {
          display: none;
        }
      }
    }
  }

  .help-link {
    display: inline-block;
    font-size: 14px;
    line-height: 16px;
    color: #212529;
    margin: 10px 0 0;

    &:hover {
      text-decoration: underline;
    }
  }

  .submit-row {
    text-align: center;
    margin: 48px 0 0;

    .btn {
      font-size: 14px;
      line-height: 16px;
      padding: 12.3072px 36.9216px;

      @include media(">=widescreen") {
        font-size: 16px;
        line-height: 18px;
        padding: 14.5969px 43.7907px;
        border-radius: 29.1938px;
      }
    }
  }
}

.login-form {
  padding: 0 2px;
}

.success-form {
  &.continue-create-account {
    .form-row {
      &.submit-row {
        display: none;
        &.success-submit {
          display: block;
        }
      }
    }

    .abn-state {
      display: block;
    }
  }

  .form-row {
    &.submit-row {
      display: block;
      &.success-submit {
        display: none;
        margin: 47px 0 0;
      }
    }
  }

  .abn-state {
    display: none;
  }
}

.create-account-form.form-elements {
  label {
    font-size: 14px;
    line-height: 17px;

    @include media(">=widescreen") {
      font-size: 16.6005px;
      line-height: 20px;
    }
  }

  .form-row {
    &:not(:last-child) {
      @include media(">=widescreen") {
        margin: 0 0 31px;
      }
    }
  }

  .custom-checkbox {
    font-size: 12px;
    line-height: 20px;
    color: #212529;
    margin: 35px 0 0;

    @include media(">=widescreen") {
      font-size: 14px;
      line-height: 20px;
      margin: 55px 0 67px 9px;
    }

    a {
      color: #0076ec;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .submit-row {
    margin: 59px 0 0;
    @include media(">=desktop-wide") {
      margin: 52px 0 0;
    }
  }
}

.form-help-text {
  max-width: 393px;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #212529;
  margin: 31px auto 0;

  @include media(">=widescreen") {
    font-size: 16px;
    line-height: 28px;
    max-width: 474.12px;
    margin: 0 auto;
  }

  a {
    color: #212529;

    &:hover {
      text-decoration: underline;
    }
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.multiselect-container {
  .chip {
    white-space: unset;
  }
}
