.capitalize {
	text-transform: capitalize;
}

.lowercase {
	text-transform: lowercase;
}

.uppercase {
	text-transform: uppercase;
}
