// Typography

// @include title-size('≥');

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.h {
	font-family: $headings-font-family;
	color: $headings-color;
	font-weight: bold;
	margin: 0 0 0.3em;

	@include media('>=tablet') {
		margin-bottom: 20px;
	}
}

h1, .h1 {
	line-height: calc(54/48);

	@include media('>=desktop-wide') {
		line-height: calc(54/48);
	}

	@include media('>=widescreen') {
		line-height: calc(70/64);
	}
}

h2, .h2 {
	line-height: calc(20.7/18);

	@include media('>=phone') {
		line-height: calc(27.75/25);
	}

	@include media('>=tablet') {
		line-height: calc(46/40);
	}

	@include media('>=tablet') {
		line-height: calc(34.5/30);
	}

	@include media('>=widescreen') {
		line-height: calc(46/40);
	}
}

h3, .h3 {
	line-height: calc(35/24);
}

h4, .h4 {
	line-height: calc(11.5/10);

	@include media('>=phone') {
		line-height: calc(13.8/12);
	}

	@include media('>=tablet') {
		line-height: calc(20.7/18);
	}

	@include media('>=widescreen') {
		line-height: calc(23/20);
	}
}

h5, .h5 {
	line-height: calc(22.5/16);
}

p {
	margin: 0 0 1em;
}

a {
	color: $ct-blue;
	text-decoration: $text-decoration;
	transition: color .27s ease-in-out;

	&:hover,
	&:focus {
		color: $ct-dark-blue; // color: $base-link-hover-color;
		text-decoration: $text-decoration-hover;
	}
}

a[href*="mailto:"] {
	word-wrap: break-word;
}

.bold {
	font-weight: 700;
	font-size: 14px;
	line-height: 1.1;
	color: $black;
}

a {
	color: #0035F1;
}