.card-wl {
  @include media(">=desktop-wide") {
    margin: 0;
    width: 415px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    gap: 12px;
    justify-content: flex-start;
  }
}
