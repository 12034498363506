.step-block {
  padding: 37px 30px;

  @include media(">=desktop-wide") {
    padding: 59px 65px 60px 73px;
  }

  .df-row {
    margin: 0 -10px;

    @include media(">=desktop-wide") {
      margin: 0 -15px;
    }
  }

  [class*="col-"] {
    padding: 0 10px;

    @include media(">=desktop-wide") {
      padding: 0 15px;
    }
  }

  .col-content {
    width: 100%;

    @include media(">=desktop-wide") {
      flex: 1 0 0;
      width: auto;
    }
  }

  .col-info {
    @include media("<desktop-wide") {
      width: 100%;
      margin-top: 20px;
    }
  }

  .step-content {
    @include media("<desktop-wide") {
      max-width: 266px;
      margin: auto;
    }
  }

  .step-title {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    font-weight: 700;
    font-size: 18px;
    line-height: 1.2;

    svg {
      margin-right: 15px;
    }
  }

  .step-content {
    @include media(">=desktop-wide") {
      padding-right: 25px;
    }

    p {
      font-size: 16px;
      line-height: 1.4;
      color: $black;
      margin-bottom: 0;

      + p {
        margin-top: 19px;
      }
    }
  }

  .card-lvl {
    padding: 40px;

    // @include media('>=desktop-wide') {
    // 	padding: 40px;
    // }

    .btn {
      min-width: 165px;
      padding: 12px 35px;
    }

    .att-list {
      @extend %listreset;

      font-size: 14px;
      line-height: 1.58;
      margin-bottom: 12px;

      strong {
        color: #6c757d;
        margin-right: 4px;
      }
    }
  }

  .form-elements {
    label {
      text-align: left;
      font-size: 9px;
      display: flex;
    }

    .text {
      max-width: 254px;
      margin: 40px auto 44px;

      p {
        margin-bottom: 0;
        font-size: 14px;
        line-height: 20px;
        color: #6c757d;
        text-align: left;
      }
    }

    .form-row {
      margin: 0;

      + .form-row {
        margin-top: 9px;
      }

      &.submit-row {
        margin-top: 2px;

        .btn {
          font-size: 14px;
          min-width: 150px;
        }
      }
    }

    input,
    select,
    .jcf-select {
      height: 30px;
      font-size: 14px;
    }

    .jcf-select {
      position: relative;
      background: #fcfbfb;

      .jcf-select-text {
        font-size: 10px;
      }
    }

    &.sect-multiple {
      .form-two-col {
        margin-top: 31px;
        margin-bottom: 33px;
        padding: 0 12px 0 6px;

        @include media(">=desktop-wide") {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .form-row-item {
          + {
            .form-row-item {
              margin-top: 10px;

              @include media(">=desktop-wide") {
                margin-top: 0;
              }
            }
          }
        }
      }

      .jcf-select-drop {
        padding-top: 17px;
        overflow: hidden;
        top: calc(100% - 6px);
      }

      .jcf-option {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        padding: 13px 24px 13px 13px;
      }

      .select-row {
        text-align: left;
        margin-bottom: 12px;
      }

      .jcf-select {
        height: auto;
        border-radius: 0;
        border: 1.22135px solid #ced4da;
        padding: 13px 30px 12px 15px;
      }

      .jcf-select-opener {
        transform: none;
        top: 15px;
      }

      .jcf-select-text {
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        color: $black;
      }

      label {
        font-size: 9px;
      }

      .step2-label {
        font-size: 14px;
      }

      .link {
        display: inline-block;
        vertical-align: middle;
        font-size: 12px;
        margin-top: 15px;
        margin-left: 16px;
        color: #0574d6;
      }
    }

    select {
      font-size: 14px;
    }
  }

  .certificate-list {
    @extend %listreset;

    display: flex;
    justify-content: center;
    margin: -14px 0 13px 0;

    li {
      position: relative;

      &:first-child {
        z-index: 4;
      }

      &:nth-child(2) {
        z-index: 3;
      }

      &:nth-child(3) {
        z-index: 2;
      }

      + li {
        margin-left: -10px;
      }
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 52px;
      height: 52px;
      border-radius: 50%;
      overflow: hidden;
      background: #dadada;
      border: 3px solid #f7f7f7;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: #343a40;

      img {
        width: 80%;
      }
    }
  }

  .popup-link {
    font-weight: 400;
    font-size: 10px;
    line-height: 17px;
    color: #0574d6;

    &:hover {
      text-decoration: underline;
    }
  }

  .text-sm {
    max-width: 214px;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    margin: 17px auto 21px;

    p {
      color: #6c757d;
      margin-bottom: 0;
    }
  }

  .share-block {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 17px;
    margin-bottom: 26px;

    p {
      color: #343a40;
      margin-bottom: 0;
      margin-top: 1px;
      font-size: 13px;
    }
  }

  input[type="checkbox"],
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    left: -9999999px;
    z-index: 1;

    &:checked {
      + label {
        &:before {
          background: rgba(40, 167, 69, 0.1)
            url("../../../images/check-icon.svg") no-repeat center;
        }
      }
    }

    + label {
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 14px;
      color: #343a40;

      &:before {
        content: "";
        display: block;
        margin-right: 20px;
        width: 15px;
        height: 15px;
        background-color: rgba(40, 167, 69, 0.1);
        border: 1px solid #ced4da;
        border-radius: 4px;
        margin-right: 20px;
      }
    }
  }

  textarea {
    width: 100%;
    background: inherit;
    border: none;
    padding: 0 8px;
    resize: none;
    height: 75px;
    font-size: 14px;
  }
}
