@import "../../assets/styles/all.scss";

.level-certification-block {
  .content-admin {
    .filter-label {
      font-weight: bold;
      color: $ct-dark-blue;
    }
  }
}

.container-md {
  .btn-toggle {
    background: none;
    color: $ct-blue-links;
    font-size: 14px;
    margin: 8px 17px;
    padding: 5px;
    border: 1px solid $ct-blue-links;
    border-radius: 4px;
    font-weight: normal;

    &:hover {
      color: $ct-dark-blue;
      border: 1px solid $ct-dark-blue;
      background: none;
    }
  }
}
