@import "../../assets/styles/all.scss";

.btn {
  margin: 20px 10px 20px 0;

  @include media("<=tablet") {
    margin: 6px 0 6px 0;
  }
}

.support {
  @include media("<=tablet") {
    margin-top: 10px;
  }
}

.resend {
  color: $ct-blue-links;
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }
}
