body {
  margin: 0;
  font-family: 'Arial', 'Helvetica Neue', 'Helvetica', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Arial', 'Helvetica Neue', 'Helvetica', sans-serif,
    monospace;
}

button:focus {
  outline: none;
  box-shadow: none;
}
