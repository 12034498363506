.card-compl {
	padding: 39px 43px 42px 38px;
	background: #fdfdfd;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
	border-radius: 10px;

	@include media(">=desktop-wide") {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.main-heading {
		width: 100%;
	}

	.status-label-list {
		font-size: 11px;
		padding: 2px 2px;
		margin: 0;

		a {
			padding: 3px 7px;
			display: block;
		}
	}

	.table-holder {
		overflow: hidden;
		overflow-x: auto;
		padding: 20px 0 0 0;

		@include media(">=desktop-wide") {
			padding: 55px 0 0 0;
		}

		.table-wrap {
			width: 745px;

			overflow-x: auto;
		}
	}

	.table-title {
		display: block;
		font-weight: 700;
		line-height: 1.7;
		color: #000;
		margin-bottom: 15px;

		@include media(">=desktop-wide") {
			margin-bottom: 23px;
		}
	}

	.table {
		width: 100%;
		background: #f7f7f7;
		border-radius: 10px;
		box-shadow: none;
		overflow: auto;
		margin-bottom: 0;

		thead {
			border-bottom: 1px solid #dee2e6;

			tr {
				td {
					font-weight: 700;
					font-size: 11px;
					line-height: 18px;
					letter-spacing: 0.6px;
					color: #6c757d;
					padding: 15px;
					border: 0;

					@include media(">=desktop-wide") {
						padding: 17px 34px;
					}
				}
			}
		}

		tbody {
			border: 0;
			background: $white;
		}

		tr {
			td {
				border: 0;
				font-size: 12px;
				line-height: 18px;
				color: #343a40;
				padding: 20px 29px;

				@include media(">=desktop-wide") {
					padding: 20px 34px;
				}

				// &:last-child {
				// 	font-size: 13px;
				// }

				strong {
					font-weight: 700;
					font-size: 12px;
					line-height: 18px;
					color: #343a40;
				}
			}
		}
	}

	.form-heading {
		display: block;
		font-size: 18px;
		line-height: 30px;
		color: #343a40;
		margin-bottom: 20px;
		margin-top: 20px;

		@include media(">=desktop-wide") {
			margin-top: 0;
			margin-bottom: 28px;
		}
	}
}

.card-compl-admin {
	@extend .card-compl;

	padding: 0;
	background: none;
	box-shadow: 0;
	border-radius: 0;

	.table {
		thead {
			background: $white;
			text-transform: uppercase;

			tr {
				td {
					padding: 16px;
				}
			}
		}

		tr {
			border: 1px solid $gray-300;

			.sticky-col {
				position: -webkit-sticky;
				position: sticky;
				background-color: $white !important;
			}

			.select-col {
				width: 80px;
				min-width: 80px;
				max-width: 80px;
				left: 0px;
				text-align: center;
			}

			.action-col {
				width: 80px;
				min-width: 80px;
				max-width: 80px;
				left: 80px;

				.edit-delete {
					display: flex;
					gap: 8px;

					img {
						align-items: center;
						cursor: pointer;
					}
				}
			}

			td {
				vertical-align: middle;
				padding: 16px;
			}

			.account-type {
				border-radius: 4px;
				padding: 2px 4px;
				width: 55px;
				text-align: center;
				font-size: 11px;
				margin: 2px 0;
			}

			.partner-type {
				background: $ct-dark-blue;
				color: white;
			}

			.prime-type {
				background: $ct-blue;
				color: white;
			}

			.supplier-type {
				background: $ct-blue-links;
				color: white;
			}
		}
	}

	.table-holder {
		padding: 0;
		margin: 0;

		.table-wrap {
			width: 820px;

			@include media(">widescreen-sm") {
				width: 100%;
			}
		}
	}
}
