@import "../../assets/styles/all.scss";

a {
  cursor: pointer;
}

.admin-id {
  font-size: 10px;
  font-weight: bold;
  color: $ct-dark-blue;
  background: #f9f9f9;
  padding: 4px 10px;
  border-radius: 30px;
  transform: translate(-40px, 0);
  text-transform: uppercase;
}