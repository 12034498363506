.custom-table-holder {
	width: 100%;
	margin-bottom: 20px;

	@include media('>=650px') {
		margin-bottom: 0;
	}

	@include media('>=desktop-wide') {
		width: 290px;
	}

	.table-heading {
		display: flex;
		align-items: center;
		margin-bottom: 40px;
		font-size: 18px;
		line-height: 30px;
		color: $black;

		svg {
			margin-right: 15px;
		}
	}
}

.custom-table {
	@extend %listreset;

	display: grid;
	grid-template-columns: 50% 50%;
	row-gap: 26px;
	column-gap: 10px;

	.tc-title {
		display: block;
		margin-bottom: 18px;
		font-weight: 700;
		font-size: 11px;
		line-height: 18px;
		letter-spacing: 0.6px;
		color: #6C757D;
	}

	span {
		font-weight: 400;
		font-size: 14px;
		line-height: 18px;
		color: $gray-800;
	}
}