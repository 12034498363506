.f-col-spread {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.f-col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.f-col-start {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.f-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.f-row-even {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.f-row-spread-start {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 40px;
}

.f-row-spread {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.f-row-start {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.f-row-start-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.f-row-center-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  // see if this causes errors in other areas
  // added to add mg-b for register btn
  margin-bottom: 30px;
}

.f-row-center-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.f-grid-two-col {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
}

.logo {
  height: 53px;
}

.banner {
  height: 425px;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.h-100 {
  height: 100%;
}

.h-50 {
  height: 50%;
}

.mt-30 {
  margin-top: 30px;
}

.mt-56 {
  margin-top: 56px;
}

.capitalize {
  text-transform: capitalize;
}
