@import "../../assets/styles/all.scss";

.nf-super-container {
  width: 100%;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;

  @include media("<=desktop-wide") {
    .f-row-center {
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
      margin: 0;
      padding: 50px;
    }
  }

  .nf-container {
    margin: 120px auto;

    @include media("<=desktop-wide") {
      margin: 30px auto;
    }

    .content {
      width: 100%;
      margin-left: 130px;

      @include media("<=desktop-wide") {
        margin-left: 0;
      }
    }

    .nf-title,
    .heading2 {
      color: $ct-dark-blue;
      font-size: 48px;
      max-width: 400px;
    }

    .heading2 {
      margin-bottom: 32px;
    }

    p {
      font-size: 18px;
      color: $ct-text-grey;

      @include media("<=desktop-wide") {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .btn.btn-warning-gd {
      margin: 20px 0 30px 0;
    }

    a {
      font-weight: bold;
    }

    .get-help {
      margin-top: 30px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      img {
        margin: -12px 0 0 10px;
      }

      p {
        font-size: 14px;
        font-weight: bold;
        color: $ct-dark-blue;
      }
    }

    .description {
      font-size: 12px;
      width: 285px;

      @include media("<=desktop-wide") {
        line-height: 18px;
      }
    }
  }

  .nf-illustration {
    pointer-events: none;
    width: 100%;

    @include media("<=desktop-wide") {
      margin: 30px auto 80px;
    }

    img {
      width: 526px;
    }
  }
}
