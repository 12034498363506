@import "./colors.scss";
@import "./fonts";

.form-label {
  padding: 2px 6px 0;
  margin: 0;
  color: $secondary;
  font-size: 9px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.form-input {
  background-color: transparent !important;
  border: none !important;
  width: 100%;
  border-radius: 0;
}

.form-input:-webkit-autofill,
.form-input:-webkit-autofill:hover,
.form-input:-webkit-autofill:focus,
.form-input:-webkit-autofill:active {
  // border: 1px solid green;
  // -webkit-text-fill-color: green;
  -webkit-box-shadow: 0 0 0px 1000px inherit inset;
  transition: background-color 5000s ease-in-out 0s;
}

.passwd-input-group,
.search-input {
  position: relative;

  .show-passwd-btn,
  .search-btn {
    border: none;
    background: none;
    position: absolute;
    right: -6px;
    top: 50%;
    transform: translateY(-50%);
    color: $ct-blue;
  }
}

.checkbox-label {
  padding: 0;
  margin: 0;
  margin-left: 12px;
  @extend .normal4;
}
