.tab-form {
	width: 100%;

	@include media('>=desktop-wide') {
		margin-bottom: 16px;
	}

	.form-row {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-end;
		margin: 0 -20px 0;

		+ .form-row {
			@include media('>=desktop-wide') {
				margin-top: 30px;
			}
		}
	}

	.col-form {
		padding: 0 20px;
		max-width: 100%;
		flex: 0 0 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-bottom: 20px;

		@include media('>=desktop-wide') {
			max-width: 50%;
			flex: 0 0 50%;
			margin-bottom: 0;
		}
	}

	.col-form-btn {
		width: 100%;
		padding: 0 20px;
		display: flex;
		justify-content: flex-end;
		// padding-top: 10px;
		margin-top: 0; //25px
	}

	.form-item {
		label {
			display: block;
			font-size: 14px;
			line-height: 21px;
			color: #6C757D;
			margin-bottom: 8px;
		}

		span {
			font-size: 14px;
			line-height: 21px;
			color: #6C757D;
			display: block;
			margin-bottom: 9px;
			margin-top: 21px;

			&.js-password-toggle {
				position: absolute;
				margin: 0;
				right: 10px;
				top: 50%;
				transform: translateY(-50%);
				line-height: 1;
				cursor: pointer;

				svg {
					&:last-child {
						display: none;
					}
				}
			}
		}

		input[type="text"] {
			+ .js-password-toggle {
				svg {
					&:first-child {
						display: none;
					}

					&:last-child {
						display: block;
					}
				}
			}
		}

		input {
			background: #F3F3F3;
			border: 1px solid #DEE2E6;
			border-radius: 4px;
			width: 100%;
			height: 35px;
			padding: 10px 8px;
			font-weight: 400;
			font-size: 14px;
			line-height: 21px;
			color: #6C757D;

			@include media('>=phone') {
				height: 48px;
			}

			&:focus {
				background: #ffffff;
				border: 1px solid $ct-blue;
			}

			&:disabled {
			background: #ebebeb;		
			cursor: not-allowed;

				&:active {
					border: 1px solid $ct-blue;
				}
			}

			@include placeholder {
				color: #6C757D;
			}
		}
	}

	.form-item-row {
		display: flex;

		.form-item {
			margin-top: 0;

			&:first-child {
				input {
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
				}
			}

			&:nth-child(2) {
				width: 97px;
				input {
					border-radius: 0;
				}
			}

			&:last-child {
				width: 94px;

				input {
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
				}
			}
			
			+ .form-item {
				margin-left: -1px;
			}
		}
	}

	.checkbox-row {
		padding: 0 20px;

		@include media('>=desktop-wide') {
			margin-bottom: 27px;
    		margin-top: 38px;
		}

		label {
			font-size: 14px;
			line-height: 16px;
			display: flex;
			align-items: center;
			color: #6C757D;
		}

		.label-title {
			display: block;
			margin-bottom: 8px;
			color: #6C757D;
			font-weight: 700;
			font-size: 14px;
			line-height: 16px;
		}
	}

	input[type="checkbox"] {
		position: absolute;
		opacity: 0;
		left: -9999999px;
		z-index: 1;

		&:checked {
			+ label {
				&:before {
					background:rgba(40, 167, 69, 0.1) url("../../../images/check-icon.svg") no-repeat center;
				}
			}
		}

		+ label {
			display: flex;
			align-items: flex-start;
			cursor: pointer;
			max-width: 615px;

			&:before {
				content: '';
				display: block;
				margin-right: 20px;
				max-width: 15px;
				flex: 0 0 15px;
				height: 15px;
				background: hwb(208 42% 51% / 0.2);
				border: 1px solid hwb(208 42% 51% / 0.2);
				border-radius: 4px;
				margin-right: 15px;

				@include media('>=phone') {
					margin-right: 24px;
				}
			}
		}
	}

	.pass-wrap {
		position: relative;
	}

	.btn {
		display: flex;
		align-items: center;
		// min-width: 148px;
		padding: 12px 34px;
		font-size: 14px;

		svg {
			margin-right: 8px;
		}
	}
}