.card-lvl {
  padding: 30px;
  background: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 30px;
  text-align: center;
  max-width: 400px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  // margin-top: 20px;

  @include media(">=phone") {
    // padding: 40px 30px;
  }

  @include media(">=tablet") {
    margin-left: 0;
    margin-right: 0;
    // margin-top: 40px;
    max-width: none;
  }

  @include media(">=desktop-wide") {
    width: 360px;
    padding: 50px 75px 40px;
  }

  &.card-lvl-attestation {
    .card-title {
      margin-bottom: 12px;
    }

    .card-subtitle {
      margin-bottom: 30px;
    }
  }

  .card-title {
    margin-bottom: 32px;
    font-weight: 700;
    font-size: 18px;
    line-height: 1;
    color: #343a40;
    text-align: center;
  }

  .card-subtitle {
    display: block;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }

  .download-list {
    @extend %listreset;

    max-width: 250px;
    margin: 0 auto 12px;

    li {
      + li {
        margin-top: 6px;
      }
    }

    a {
      display: flex;
      align-items: center;
      text-align: left;
      margin-left: 7px;
      font-size: 13px;
      line-height: 18px;
      text-decoration: underline;
      color: #343a40;

      &:hover {
        text-decoration: none;
      }

      svg {
        margin-right: 13px;
      }
    }
  }

  .btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    // min-width: 187px;
    padding: 12px 25px;
    margin: 20px auto 0;

    svg {
      margin-right: 10px;
    }
  }

  .badgeSeal {
    margin-bottom: 32px;
    width: 60%;
  }
}
