@import "../../assets/styles/all.scss";

.dashboard-header-admin {
    @extend .dashboard-header;
    padding: 56px 0 0 0;

    .container-md {
        @include media(">widescreen-sm") {
            max-width: 1440px;
        }

        .card-wl {
            @include media(">widescreen-sm") {
                margin: 0;
                width: 100%;
            }
        }
    }
}

.admin-page {
    .org-details {
        margin: 10px 0 0;

        @include media("<desktop-wide") {
            width: 100%;
        }

        .label {
            color: $ct-text-grey;
        }

        .org-name {
            line-height: 22px;
            margin-bottom: 4px;
            margin-top: 12px;
            text-transform: capitalize;
            font-weight: bold;
            font-size: 16px;
            color: $ct-dark-blue;

            .account-type {
                border-radius: 4px;
                padding: 2px 4px;
                width: 55px;
                text-align: center;
                font-size: 11px;
                margin: 0 2px;
            }

            .partner-type {
                background: $ct-dark-blue;
                color: white;
            }

            .prime-type {
                background: $ct-blue;
                color: white;
            }

            .supplier-type {
                background: $ct-blue-links;
                color: white;
            }

            a {
                color: $ct-dark-blue;

                &:hover {
                    color: $ct-blue-links;
                }
            }
        }

        p {
            margin: 10px 0;

            b {
                color: $dark;
            }
        }
    }

    .quick-actions {
        .label {
            color: $ct-text-grey;
        }
    }

    .active-campaigns {
        gap: 10px;
    }
}

.level-certification-block {
    .container-md {
        @include media(">widescreen-sm") {
            max-width: 1440px;
        }
    }
}
