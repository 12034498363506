@import "../../assets/styles/all.scss";

.recent-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .org-name {
    text-transform: lowercase;

    span {
      text-transform: capitalize;
    }
  }

  p {
    overflow: hidden;
    white-space: nowrap;
  }

  .options {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    img {
      margin-left: 30px;
      cursor: pointer;
    }
  }
}

.filter-btn {
  font-weight: normal !important;
  font-size: 14px !important;
  border-radius: 6px !important;
  padding: 10px !important;
  color: $ct-blue !important;
  background: #e7ecf8 !important;
  border: 1px solid #b6c6e8 !important;

  &:hover,
  &.active {
    background: $ct-blue !important;
    color: white !important;
    border: 1px solid #dee2e6 !important;
  }
  &:focus {
    outline: 1px dotted $ct-blue-links;
    outline: 5px auto -webkit-focus-ring-color;
  }
}
