.requirement-option {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 13px;

  &:hover {
    background: #f5f5f5;
  }

  &.done {
    background: #ecfff0;

    &:hover {
      background: #ecfff0;
    }
  }
}

.form-control {
  padding: 0 6px;
}

.form-control:focus {
  outline: none !important;
  box-shadow: none !important;
}
